/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
.awsui_layout_5gtk3_1kjsb_145:not(#\9) {
  --awsui-content-layout-default-horizontal-padding-g964ok: var(--space-layout-content-horizontal-7l52k3, 24px);
  --awsui-content-layout-max-content-width-g964ok: 0px;
  --awsui-content-layout-main-gap-g964ok: 0px;
  display: grid;
  grid-template-columns: 0 0 1fr minmax(0, var(--awsui-content-layout-max-content-width-g964ok)) 1fr 0 0;
  grid-template-rows: var(--awsui-content-layout-main-gap-g964ok) min-content min-content auto var(--space-dark-header-overlap-distance-lxpp0s, 36px) 1fr;
  min-block-size: 100%;
}
@media (max-width: 688px) {
  .awsui_layout_5gtk3_1kjsb_145:not(#\9) {
    --awsui-content-layout-default-horizontal-padding-g964ok: var(--space-l-t419sm, 20px);
  }
}
.awsui_layout_5gtk3_1kjsb_145 > .awsui_background_5gtk3_1kjsb_159:not(#\9) {
  grid-column: 1/8;
  grid-row: 1/6;
  color: var(--color-text-body-default-7v1jfn, #0f141a);
}
.awsui_layout_5gtk3_1kjsb_145 > .awsui_background_5gtk3_1kjsb_159.awsui_is-overlap-disabled_5gtk3_1kjsb_164:not(#\9) {
  grid-row: 1/5;
}
.awsui_layout_5gtk3_1kjsb_145 > .awsui_background_5gtk3_1kjsb_159 > .awsui_header-background_5gtk3_1kjsb_167:not(#\9) {
  inline-size: 100%;
  block-size: 100%;
}
.awsui_layout_5gtk3_1kjsb_145 > .awsui_notifications_5gtk3_1kjsb_171:not(#\9) {
  grid-column: 4;
  grid-row: 2;
  padding-block-end: var(--space-xs-zb16t3, 8px);
}
.awsui_layout_5gtk3_1kjsb_145 > .awsui_breadcrumbs_5gtk3_1kjsb_176:not(#\9) {
  grid-column: 4;
  grid-row: 3;
  padding-block-end: var(--space-xs-zb16t3, 8px);
}
.awsui_layout_5gtk3_1kjsb_145.awsui_default-padding_5gtk3_1kjsb_181:not(#\9) {
  --awsui-content-layout-main-gap-g964ok: var(--space-scaled-m-mo5yse, 16px);
  grid-template-columns: var(--awsui-toggles-left-width-g964ok, 0) var(--awsui-content-layout-default-horizontal-padding-g964ok, 0) 1fr minmax(0, var(--awsui-content-layout-max-content-width-g964ok)) 1fr var(--awsui-content-layout-default-horizontal-padding-g964ok, 0) var(--awsui-toggles-right-width-g964ok, 0);
}
.awsui_layout_5gtk3_1kjsb_145 > .awsui_header-wrapper_5gtk3_1kjsb_185:not(#\9) {
  grid-column: 4;
  grid-row: 4;
  padding-block-end: var(--space-content-header-padding-bottom-y2enua, 16px);
}
.awsui_layout_5gtk3_1kjsb_145 > .awsui_header-wrapper_5gtk3_1kjsb_185.awsui_with-divider_5gtk3_1kjsb_190:not(#\9) {
  border-block-end: 1px solid var(--color-border-divider-default-cx07f2, #c6c6cd);
}
.awsui_layout_5gtk3_1kjsb_145 > .awsui_content_5gtk3_1kjsb_193:not(#\9) {
  grid-column: 4;
  grid-row: 5/8;
}
.awsui_layout_5gtk3_1kjsb_145:not(#\9):not(.awsui_has-header_5gtk3_1kjsb_197) {
  grid-template-rows: var(--awsui-content-layout-main-gap-g964ok) min-content min-content 0 calc(var(--space-dark-header-overlap-distance-lxpp0s, 36px)) 1fr;
}
.awsui_layout_5gtk3_1kjsb_145.awsui_is-overlap-disabled_5gtk3_1kjsb_164:not(#\9) {
  grid-template-rows: var(--awsui-content-layout-main-gap-g964ok) min-content min-content auto 0 1fr;
}

.awsui_layout_5gtk3_1kjsb_145.awsui_is-visual-refresh_5gtk3_1kjsb_204 > .awsui_background_5gtk3_1kjsb_159.awsui_has-default-background_5gtk3_1kjsb_204:not(#\9) {
  background-color: var(--color-background-layout-main-nqw91u, #ffffff);
}

.awsui_layout_5gtk3_1kjsb_145:not(#\9):not(.awsui_is-visual-refresh_5gtk3_1kjsb_204).awsui_has-notifications_5gtk3_1kjsb_208 {
  --awsui-content-layout-main-gap-g964ok: 0px;
}
.awsui_layout_5gtk3_1kjsb_145:not(#\9):not(.awsui_is-visual-refresh_5gtk3_1kjsb_204) > .awsui_notifications_5gtk3_1kjsb_171 {
  grid-column: 1/8;
}