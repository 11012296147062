/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-implicit-descendant, selector-max-type */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
.awsui_text-content_6absk_aifgb_146:not(#\9) {
  border-collapse: separate;
  border-spacing: 0;
  box-sizing: border-box;
  caption-side: top;
  cursor: auto;
  direction: inherit;
  empty-cells: show;
  font-family: serif;
  font-style: normal;
  font-variant: normal;
  font-stretch: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: start;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  word-spacing: normal;
  font-size: var(--font-size-body-m-x4okxb, 14px);
  line-height: var(--line-height-body-m-30ar75, 20px);
  color: var(--color-text-body-default-7v1jfn, #0f141a);
  font-weight: 400;
  font-family: var(--font-family-base-dnvic8, "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  /* stylelint-disable no-descending-specificity */
  /* stylelint-enable no-descending-specificity */
}
.awsui_text-content_6absk_aifgb_146 h1:not(#\9),
.awsui_text-content_6absk_aifgb_146 h2:not(#\9),
.awsui_text-content_6absk_aifgb_146 h3:not(#\9),
.awsui_text-content_6absk_aifgb_146 h4:not(#\9),
.awsui_text-content_6absk_aifgb_146 h5:not(#\9),
.awsui_text-content_6absk_aifgb_146 p:not(#\9) {
  font-family: inherit;
  font-weight: normal;
  text-decoration: none;
  margin-block: 0;
  margin-inline: 0;
  padding-block: var(--space-xxs-p8yyaw, 4px);
  padding-inline: 0;
}
.awsui_text-content_6absk_aifgb_146 h1:not(#\9),
.awsui_text-content_6absk_aifgb_146 h2:not(#\9),
.awsui_text-content_6absk_aifgb_146 h3:not(#\9),
.awsui_text-content_6absk_aifgb_146 h4:not(#\9),
.awsui_text-content_6absk_aifgb_146 h5:not(#\9) {
  color: var(--color-text-heading-default-jen9ei, #0f141a);
}
.awsui_text-content_6absk_aifgb_146 h1:not(#\9) {
  font-size: var(--font-size-heading-xl-ypc05a, 24px);
  line-height: var(--line-height-heading-xl-avbttk, 30px);
  letter-spacing: var(--letter-spacing-heading-xl-sagq9c, -0.02em);
  font-weight: var(--font-weight-heading-xl-yvsksd, 700);
  -webkit-font-smoothing: var(--font-smoothing-webkit-m8nc84, antialiased);
  -moz-osx-font-smoothing: var(--font-smoothing-moz-osx-foywby, grayscale);
}
.awsui_text-content_6absk_aifgb_146 h2:not(#\9) {
  font-size: var(--font-size-heading-l-ou0d0l, 20px);
  line-height: var(--line-height-heading-l-mmm3my, 24px);
  letter-spacing: var(--letter-spacing-heading-l-qqjd4y, -0.015em);
  font-weight: var(--font-weight-heading-l-f8711v, 700);
  -webkit-font-smoothing: var(--font-smoothing-webkit-m8nc84, antialiased);
  -moz-osx-font-smoothing: var(--font-smoothing-moz-osx-foywby, grayscale);
}
.awsui_text-content_6absk_aifgb_146 h3:not(#\9) {
  font-size: var(--font-size-heading-m-sjd256, 18px);
  line-height: var(--line-height-heading-m-50evfk, 22px);
  letter-spacing: var(--letter-spacing-heading-m-0w575i, -0.01em);
  font-weight: var(--font-weight-heading-m-m2ekmb, 700);
  -webkit-font-smoothing: var(--font-smoothing-webkit-m8nc84, antialiased);
  -moz-osx-font-smoothing: var(--font-smoothing-moz-osx-foywby, grayscale);
}
.awsui_text-content_6absk_aifgb_146 h4:not(#\9) {
  font-size: var(--font-size-heading-s-yykzif, 16px);
  line-height: var(--line-height-heading-s-4i6ewn, 20px);
  letter-spacing: var(--letter-spacing-heading-s-jr3mor, -0.005em);
  font-weight: var(--font-weight-heading-s-cwn6wc, 700);
  -webkit-font-smoothing: var(--font-smoothing-webkit-m8nc84, antialiased);
  -moz-osx-font-smoothing: var(--font-smoothing-moz-osx-foywby, grayscale);
}
.awsui_text-content_6absk_aifgb_146 h5:not(#\9) {
  font-size: var(--font-size-heading-xs-wo4hpf, 14px);
  line-height: var(--line-height-heading-xs-aeleja, 18px);
  font-weight: var(--font-weight-heading-xs-l7rqme, 700);
  -webkit-font-smoothing: var(--font-smoothing-webkit-m8nc84, antialiased);
  -moz-osx-font-smoothing: var(--font-smoothing-moz-osx-foywby, grayscale);
}
.awsui_text-content_6absk_aifgb_146 b:not(#\9),
.awsui_text-content_6absk_aifgb_146 strong:not(#\9) {
  font-weight: 700;
}
.awsui_text-content_6absk_aifgb_146 p:not(#\9) {
  font-size: var(--font-size-body-m-x4okxb, 14px);
  line-height: var(--line-height-body-m-30ar75, 20px);
  color: inherit;
}
.awsui_text-content_6absk_aifgb_146 small:not(#\9) {
  display: inline-block;
  font-size: var(--font-size-body-s-asqx2i, 12px);
  line-height: var(--line-height-body-s-7zv1j5, 16px);
  letter-spacing: var(--letter-spacing-body-s-z9jkwp, 0.005em);
  color: var(--color-text-small-nzfntg, #656871);
}
.awsui_text-content_6absk_aifgb_146 small a:not(#\9) {
  font-size: inherit;
  text-underline-offset: 0.25em;
  text-decoration-thickness: 1px;
  color: var(--color-text-link-default-enwvrt, #006ce0);
  font-weight: inherit;
  letter-spacing: normal;
  text-decoration-line: underline;
  text-decoration-color: currentColor;
  transition-property: color, -webkit-text-decoration;
  transition-property: color, text-decoration;
  transition-property: color, text-decoration, -webkit-text-decoration;
  transition-duration: var(--motion-duration-refresh-only-medium-nf6485, 165ms);
}
@media (prefers-reduced-motion: reduce) {
  .awsui_text-content_6absk_aifgb_146 small a:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_text-content_6absk_aifgb_146 small a:not(#\9), .awsui-mode-entering .awsui_text-content_6absk_aifgb_146 small a:not(#\9) {
  animation: none;
  transition: none;
}
.awsui_text-content_6absk_aifgb_146 small a:not(#\9):hover {
  cursor: pointer;
  color: var(--color-text-link-hover-49uoe9, #002b66);
}
.awsui_text-content_6absk_aifgb_146 small a:not(#\9):focus {
  outline: none;
}
.awsui_text-content_6absk_aifgb_146 small a:not(#\9):active {
  color: var(--color-text-link-hover-49uoe9, #002b66);
}
.awsui_text-content_6absk_aifgb_146 small a:not(#\9):active, .awsui_text-content_6absk_aifgb_146 small a:not(#\9):focus, .awsui_text-content_6absk_aifgb_146 small a:not(#\9):hover {
  text-decoration-line: underline;
  text-decoration-color: currentColor;
}
.awsui_text-content_6absk_aifgb_146 code:not(#\9),
.awsui_text-content_6absk_aifgb_146 pre:not(#\9),
.awsui_text-content_6absk_aifgb_146 samp:not(#\9) {
  font-family: var(--font-family-monospace-bftdwf, Monaco, Menlo, Consolas, "Courier Prime", Courier, "Courier New", monospace);
  background: transparent;
}
.awsui_text-content_6absk_aifgb_146 code:not(#\9) {
  font-size: var(--font-size-body-s-asqx2i, 12px);
  line-height: var(--line-height-body-s-7zv1j5, 16px);
  letter-spacing: var(--letter-spacing-body-s-z9jkwp, 0.005em);
}
.awsui_text-content_6absk_aifgb_146 a:not(#\9) {
  text-underline-offset: 0.25em;
  text-decoration-thickness: 1px;
  color: var(--color-text-link-default-enwvrt, #006ce0);
  font-weight: inherit;
  letter-spacing: normal;
  text-decoration-line: underline;
  text-decoration-color: currentColor;
  transition-property: color, -webkit-text-decoration;
  transition-property: color, text-decoration;
  transition-property: color, text-decoration, -webkit-text-decoration;
  transition-duration: var(--motion-duration-refresh-only-medium-nf6485, 165ms);
}
@media (prefers-reduced-motion: reduce) {
  .awsui_text-content_6absk_aifgb_146 a:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_text-content_6absk_aifgb_146 a:not(#\9), .awsui-mode-entering .awsui_text-content_6absk_aifgb_146 a:not(#\9) {
  animation: none;
  transition: none;
}
.awsui_text-content_6absk_aifgb_146 a:not(#\9):hover {
  cursor: pointer;
  color: var(--color-text-link-hover-49uoe9, #002b66);
}
.awsui_text-content_6absk_aifgb_146 a:not(#\9):focus {
  outline: none;
}
.awsui_text-content_6absk_aifgb_146 a:not(#\9):active {
  color: var(--color-text-link-hover-49uoe9, #002b66);
}
.awsui_text-content_6absk_aifgb_146 a:not(#\9):active, .awsui_text-content_6absk_aifgb_146 a:not(#\9):focus, .awsui_text-content_6absk_aifgb_146 a:not(#\9):hover {
  text-decoration-line: underline;
  text-decoration-color: currentColor;
}
.awsui_text-content_6absk_aifgb_146 a:not(#\9):focus {
  outline: thin dotted;
  outline: var(--border-link-focus-ring-outline-kkfop6, 0);
  outline-offset: 2px;
  outline-color: var(--color-border-item-focused-nv6mhz, #006ce0);
  border-start-start-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  border-start-end-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  border-end-start-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  border-end-end-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  box-shadow: 0 0 0 var(--border-link-focus-ring-shadow-spread-v8hkrl, 2px) var(--color-border-item-focused-nv6mhz, #006ce0);
}
.awsui_text-content_6absk_aifgb_146 ul:not(#\9),
.awsui_text-content_6absk_aifgb_146 ol:not(#\9) {
  padding-inline-start: var(--space-l-t419sm, 20px);
  margin-block: var(--space-scaled-xs-26e2du, 8px);
  margin-inline: 0;
  list-style-position: outside;
}
.awsui_text-content_6absk_aifgb_146 ul + ul:not(#\9),
.awsui_text-content_6absk_aifgb_146 ul + ol:not(#\9),
.awsui_text-content_6absk_aifgb_146 ul > li + ul:not(#\9),
.awsui_text-content_6absk_aifgb_146 ul > li + li:not(#\9),
.awsui_text-content_6absk_aifgb_146 ul > li + ol:not(#\9),
.awsui_text-content_6absk_aifgb_146 ul > li > ul:not(#\9),
.awsui_text-content_6absk_aifgb_146 ul > li > ol:not(#\9),
.awsui_text-content_6absk_aifgb_146 ol + ul:not(#\9),
.awsui_text-content_6absk_aifgb_146 ol + ol:not(#\9),
.awsui_text-content_6absk_aifgb_146 ol > li + ul:not(#\9),
.awsui_text-content_6absk_aifgb_146 ol > li + li:not(#\9),
.awsui_text-content_6absk_aifgb_146 ol > li + ol:not(#\9),
.awsui_text-content_6absk_aifgb_146 ol > li > ul:not(#\9),
.awsui_text-content_6absk_aifgb_146 ol > li > ol:not(#\9) {
  padding-block-start: var(--space-scaled-xxs-7597g1, 4px);
}
.awsui_text-content_6absk_aifgb_146 ul > li > ul:not(#\9),
.awsui_text-content_6absk_aifgb_146 ul > li > ol:not(#\9),
.awsui_text-content_6absk_aifgb_146 ol > li > ul:not(#\9),
.awsui_text-content_6absk_aifgb_146 ol > li > ol:not(#\9) {
  margin-block: 0;
  margin-inline: 0;
}