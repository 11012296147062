/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
.awsui_root_fvjdu_nfftc_145:not(#\9) {
  border-collapse: separate;
  border-spacing: 0;
  box-sizing: border-box;
  caption-side: top;
  cursor: auto;
  direction: inherit;
  empty-cells: show;
  font-family: serif;
  font-style: normal;
  font-variant: normal;
  font-stretch: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: start;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  word-spacing: normal;
  font-size: var(--font-size-body-m-x4okxb, 14px);
  line-height: var(--line-height-body-m-30ar75, 20px);
  color: var(--color-text-body-default-7v1jfn, #0f141a);
  font-weight: 400;
  font-family: var(--font-family-base-dnvic8, "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding-inline-start: 0;
  margin-block: 0;
  margin-inline: 0;
  list-style: none;
}

.awsui_button_fvjdu_nfftc_185:not(#\9),
.awsui_dots_fvjdu_nfftc_186:not(#\9) {
  min-inline-size: var(--space-l-t419sm, 20px);
  border-block: var(--border-width-field-h1g7tw, 2px) solid transparent;
  border-inline: var(--border-width-field-h1g7tw, 2px) solid transparent;
}

.awsui_button_fvjdu_nfftc_185:not(#\9) {
  cursor: pointer;
  text-align: center;
  box-sizing: border-box;
  background: transparent;
  line-height: inherit;
  padding-block: 0;
  padding-inline: 0;
}
.awsui_button_fvjdu_nfftc_185:not(#\9):focus {
  outline: none;
}
body[data-awsui-focus-visible=true] .awsui_button_fvjdu_nfftc_185:not(#\9):focus {
  outline: 2px dotted transparent;
  border-block: var(--border-width-field-h1g7tw, 2px) solid var(--color-border-item-focused-nv6mhz, #006ce0);
  border-inline: var(--border-width-field-h1g7tw, 2px) solid var(--color-border-item-focused-nv6mhz, #006ce0);
  border-start-start-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  border-start-end-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  border-end-start-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  border-end-end-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  box-shadow: 0 0 0 var(--border-control-focus-ring-shadow-spread-0ctpjf, 0px) var(--color-border-item-focused-nv6mhz, #006ce0);
}
.awsui_button-disabled_fvjdu_nfftc_214:not(#\9) {
  cursor: default;
  color: var(--color-text-interactive-disabled-f5b4z5, #b4b4bb);
}

.awsui_arrow_fvjdu_nfftc_219:not(#\9) {
  color: var(--color-text-interactive-default-tkx8fe, #424650);
}
.awsui_arrow_fvjdu_nfftc_219:not(#\9):not(.awsui_button-disabled_fvjdu_nfftc_214):hover {
  color: var(--color-text-interactive-hover-f9gqs8, #0f141a);
}
.awsui_arrow_fvjdu_nfftc_219.awsui_button-disabled_fvjdu_nfftc_214:not(#\9) {
  color: var(--color-text-interactive-disabled-f5b4z5, #b4b4bb);
}

.awsui_page-number_fvjdu_nfftc_229:not(#\9) {
  color: var(--color-text-pagination-page-number-default-s25kr0, #424650);
}
.awsui_page-number_fvjdu_nfftc_229:not(#\9):not(.awsui_button-disabled_fvjdu_nfftc_214):hover {
  color: var(--color-text-interactive-hover-f9gqs8, #0f141a);
}
.awsui_page-number_fvjdu_nfftc_229.awsui_button-current_fvjdu_nfftc_235:not(#\9) {
  font-weight: var(--font-wayfinding-link-active-weight-rbmzei, 700);
  -webkit-font-smoothing: var(--font-smoothing-webkit-m8nc84, antialiased);
  -moz-osx-font-smoothing: var(--font-smoothing-moz-osx-foywby, grayscale);
  color: var(--color-text-interactive-active-j6vw94, #0f141a);
}
.awsui_page-number_fvjdu_nfftc_229.awsui_button-current_fvjdu_nfftc_235.awsui_button-disabled_fvjdu_nfftc_214:not(#\9) {
  color: var(--color-text-pagination-page-number-active-disabled-a19yjt, #b4b4bb);
}
.awsui_page-number_fvjdu_nfftc_229.awsui_button-disabled_fvjdu_nfftc_214:not(#\9) {
  color: var(--color-text-interactive-disabled-f5b4z5, #b4b4bb);
}

.awsui_dots_fvjdu_nfftc_186:not(#\9) {
  color: var(--color-text-interactive-default-tkx8fe, #424650);
}

.awsui_page-item_fvjdu_nfftc_252:not(#\9),
.awsui_dots_fvjdu_nfftc_186:not(#\9) {
  margin-block: var(--space-scaled-xxs-7597g1, 4px);
  margin-inline: var(--space-xxs-p8yyaw, 4px);
  text-align: center;
  box-sizing: border-box;
  padding-block: 0;
  padding-inline: 0;
}
.awsui_page-item_fvjdu_nfftc_252:not(#\9):first-child,
.awsui_dots_fvjdu_nfftc_186:not(#\9):first-child {
  margin-inline-start: 0;
}
.awsui_page-item_fvjdu_nfftc_252:not(#\9):last-child,
.awsui_dots_fvjdu_nfftc_186:not(#\9):last-child {
  margin-inline-end: 0;
}

.awsui_root-disabled_fvjdu_nfftc_270 > .awsui_dots_fvjdu_nfftc_186:not(#\9) {
  color: var(--color-text-interactive-disabled-f5b4z5, #b4b4bb);
}