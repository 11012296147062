/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_content-enter_gwq0h_yfiql_161:not(#\9) {
  animation: awsui_awsui-motion-fade-in_gwq0h_yfiql_1 var(--motion-duration-show-paced-q2lo2h, 180ms) var(--motion-easing-show-paced-ne2o0s, ease-out);
}
@keyframes awsui_awsui-motion-fade-in_gwq0h_yfiql_1 {
  from {
    opacity: 0.2;
  }
  to {
    opacity: 1;
  }
}
@media (prefers-reduced-motion: reduce) {
  .awsui_content-enter_gwq0h_yfiql_161:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_content-enter_gwq0h_yfiql_161:not(#\9), .awsui-mode-entering .awsui_content-enter_gwq0h_yfiql_161:not(#\9) {
  animation: none;
  transition: none;
}

.awsui_trigger-expanded_gwq0h_yfiql_183:not(#\9) {
  transition: border-bottom-color var(--motion-duration-show-paced-q2lo2h, 180ms) var(--motion-easing-show-paced-ne2o0s, ease-out);
}
@media (prefers-reduced-motion: reduce) {
  .awsui_trigger-expanded_gwq0h_yfiql_183:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_trigger-expanded_gwq0h_yfiql_183:not(#\9), .awsui-mode-entering .awsui_trigger-expanded_gwq0h_yfiql_183:not(#\9) {
  animation: none;
  transition: none;
}

.awsui_icon_gwq0h_yfiql_197:not(#\9) {
  transition: transform var(--motion-duration-rotate-90-4ry26y, 135ms) var(--motion-easing-rotate-90-sb4dk7, cubic-bezier(0.165, 0.84, 0.44, 1));
}
@media (prefers-reduced-motion: reduce) {
  .awsui_icon_gwq0h_yfiql_197:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_icon_gwq0h_yfiql_197:not(#\9), .awsui-mode-entering .awsui_icon_gwq0h_yfiql_197:not(#\9) {
  animation: none;
  transition: none;
}

.awsui_root_gwq0h_yfiql_211:not(#\9) {
  border-collapse: separate;
  border-spacing: 0;
  box-sizing: border-box;
  caption-side: top;
  cursor: auto;
  direction: inherit;
  empty-cells: show;
  font-family: serif;
  font-style: normal;
  font-variant: normal;
  font-stretch: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: start;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  word-spacing: normal;
  font-size: var(--font-size-body-m-x4okxb, 14px);
  line-height: var(--line-height-body-m-30ar75, 20px);
  color: var(--color-text-body-default-7v1jfn, #0f141a);
  font-weight: 400;
  font-family: var(--font-family-base-dnvic8, "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  min-inline-size: 0;
  word-break: break-word;
  display: block;
}

.awsui_expand-button_gwq0h_yfiql_246:not(#\9) {
  outline: none;
}

.awsui_icon_gwq0h_yfiql_197:not(#\9) {
  transform: rotate(-90deg);
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
}
.awsui_icon_gwq0h_yfiql_197.awsui_expanded_gwq0h_yfiql_254:not(#\9) {
  transform: rotate(0deg);
}
.awsui_icon_gwq0h_yfiql_197:not(#\9):dir(rtl) {
  transform: rotate(90deg);
}
.awsui_icon_gwq0h_yfiql_197:not(#\9):dir(rtl).awsui_expanded_gwq0h_yfiql_254 {
  transform: rotate(0deg);
}

.awsui_icon-container_gwq0h_yfiql_264:not(#\9) {
  position: relative;
  margin-inline: calc((var(--line-height-body-m-30ar75, 20px) - var(--size-icon-normal-0m1722, 16px)) / -2) calc(var(--space-xxs-p8yyaw, 4px) + var(--border-divider-list-width-27y3k5, 1px));
}
.awsui_icon-container-container_gwq0h_yfiql_268:not(#\9) {
  margin-inline-end: var(--space-xs-zb16t3, 8px);
}

.awsui_wrapper_gwq0h_yfiql_272:not(#\9) {
  box-sizing: border-box;
  border-block: none;
  border-inline: none;
  inline-size: 100%;
  line-height: var(--line-height-body-m-30ar75, 20px);
  text-align: start;
}
.awsui_wrapper-default_gwq0h_yfiql_280:not(#\9), .awsui_wrapper-inline_gwq0h_yfiql_280:not(#\9), .awsui_wrapper-footer_gwq0h_yfiql_280:not(#\9) {
  border-block: var(--border-divider-section-width-1061zr, 1px) solid transparent;
  border-inline: var(--border-divider-section-width-1061zr, 1px) solid transparent;
}
.awsui_wrapper-navigation_gwq0h_yfiql_284:not(#\9) {
  border-inline-start: var(--border-divider-section-width-1061zr, 1px) solid transparent;
}
.awsui_wrapper-navigation_gwq0h_yfiql_284:not(#\9), .awsui_wrapper-container_gwq0h_yfiql_287:not(#\9) {
  display: flex;
  font-weight: var(--font-weight-heading-s-cwn6wc, 700);
}
.awsui_wrapper-default_gwq0h_yfiql_280:not(#\9), .awsui_wrapper-inline_gwq0h_yfiql_280:not(#\9), .awsui_wrapper-navigation_gwq0h_yfiql_284:not(#\9), .awsui_wrapper-footer_gwq0h_yfiql_280:not(#\9), .awsui_wrapper-compact_gwq0h_yfiql_291:not(#\9) {
  color: var(--color-text-expandable-section-default-7w1jwq, #0f141a);
  -webkit-font-smoothing: var(--font-smoothing-webkit-m8nc84, antialiased);
  -moz-osx-font-smoothing: var(--font-smoothing-moz-osx-foywby, grayscale);
}
.awsui_wrapper-default_gwq0h_yfiql_280:not(#\9), .awsui_wrapper-inline_gwq0h_yfiql_280:not(#\9), .awsui_wrapper-navigation_gwq0h_yfiql_284:not(#\9), .awsui_wrapper-footer_gwq0h_yfiql_280:not(#\9) {
  font-size: var(--font-expandable-heading-size-smdiul, 16px);
  letter-spacing: var(--letter-spacing-heading-s-jr3mor, -0.005em);
}
.awsui_wrapper-default_gwq0h_yfiql_280:not(#\9) {
  padding-block: var(--space-scaled-xxs-7597g1, 4px);
  padding-inline-end: var(--space-xxs-p8yyaw, 4px);
}
.awsui_wrapper-default_gwq0h_yfiql_280.awsui_header-deprecated_gwq0h_yfiql_304:not(#\9) {
  padding-inline-start: var(--space-xxs-p8yyaw, 4px);
}
.awsui_wrapper-default_gwq0h_yfiql_280:not(#\9):not(.awsui_header-deprecated_gwq0h_yfiql_304), .awsui_wrapper-inline_gwq0h_yfiql_280:not(#\9):not(.awsui_header-deprecated_gwq0h_yfiql_304) {
  padding-inline-start: calc(var(--size-icon-normal-0m1722, 16px) + (var(--line-height-body-m-30ar75, 20px) - var(--size-icon-normal-0m1722, 16px)) / -2 + var(--space-xxs-p8yyaw, 4px) + var(--border-divider-list-width-27y3k5, 1px));
}
.awsui_wrapper-default_gwq0h_yfiql_280.awsui_wrapper-expanded_gwq0h_yfiql_310:not(#\9), .awsui_wrapper-inline_gwq0h_yfiql_280.awsui_wrapper-expanded_gwq0h_yfiql_310:not(#\9) {
  padding-block-end: var(--space-scaled-xxs-7597g1, 4px);
  border-block-end-color: var(--color-border-divider-default-cx07f2, #c6c6cd);
}
.awsui_wrapper-footer_gwq0h_yfiql_280:not(#\9) {
  padding-block: var(--space-scaled-xxs-7597g1, 4px);
}
.awsui_wrapper-footer_gwq0h_yfiql_280:not(#\9), .awsui_wrapper-compact_gwq0h_yfiql_291:not(#\9) {
  padding-inline-end: 0;
}
.awsui_wrapper-footer_gwq0h_yfiql_280.awsui_header-deprecated_gwq0h_yfiql_304:not(#\9), .awsui_wrapper-compact_gwq0h_yfiql_291.awsui_header-deprecated_gwq0h_yfiql_304:not(#\9) {
  padding-inline-start: 0;
}
.awsui_wrapper-footer_gwq0h_yfiql_280:not(#\9):not(.awsui_header-deprecated_gwq0h_yfiql_304), .awsui_wrapper-compact_gwq0h_yfiql_291:not(#\9):not(.awsui_header-deprecated_gwq0h_yfiql_304) {
  padding-inline-start: calc(var(--size-icon-normal-0m1722, 16px) + (var(--line-height-body-m-30ar75, 20px) - var(--size-icon-normal-0m1722, 16px)) / -2 + var(--space-xxs-p8yyaw, 4px) + var(--border-divider-list-width-27y3k5, 1px));
}
.awsui_wrapper-container_gwq0h_yfiql_287:not(#\9) {
  padding-block: var(--space-container-header-top-5qav00, 12px) var(--space-container-header-bottom-vj01hn, 8px);
  padding-inline-end: var(--space-container-horizontal-wfukh3, 20px);
}
.awsui_wrapper-container_gwq0h_yfiql_287:not(#\9):not(.awsui_wrapper-expanded_gwq0h_yfiql_310) {
  padding-block-end: var(--space-container-header-top-5qav00, 12px);
}
.awsui_wrapper-container_gwq0h_yfiql_287.awsui_header-deprecated_gwq0h_yfiql_304:not(#\9) {
  padding-inline-start: var(--space-container-horizontal-wfukh3, 20px);
}
.awsui_wrapper-container_gwq0h_yfiql_287:not(#\9):not(.awsui_header-deprecated_gwq0h_yfiql_304) {
  padding-inline-start: calc(var(--space-container-horizontal-wfukh3, 20px) + calc(var(--size-icon-medium-y9xuoq, 20px) + (var(--line-height-body-m-30ar75, 20px) - var(--size-icon-normal-0m1722, 16px)) / -2 + var(--space-xs-zb16t3, 8px)));
}
body[data-awsui-focus-visible=true] .awsui_wrapper-container_gwq0h_yfiql_287:not(#\9):focus {
  padding-block: calc(var(--space-scaled-s-aqzyko, 12px) - var(--border-divider-section-width-1061zr, 1px));
  padding-inline: calc(var(--space-l-t419sm, 20px) - var(--border-divider-section-width-1061zr, 1px));
}

.awsui_header_gwq0h_yfiql_304:not(#\9) {
  /* used in test-utils */
}
.awsui_header-wrapper_gwq0h_yfiql_347:not(#\9), .awsui_header-deprecated_gwq0h_yfiql_304:not(#\9) {
  display: flex;
  font-weight: var(--font-weight-heading-s-cwn6wc, 700);
}
.awsui_header-wrapper_gwq0h_yfiql_347:not(#\9) {
  font-size: inherit;
  letter-spacing: inherit;
  margin-block: 0;
  margin-inline: 0;
  padding-block: 0;
  padding-inline: 0;
}
.awsui_header-actions-wrapper_gwq0h_yfiql_359:not(#\9) {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
body[data-awsui-focus-visible=true] .awsui_header-button_gwq0h_yfiql_365:not(#\9):focus, body[data-awsui-focus-visible=true] .awsui_header-container-button_gwq0h_yfiql_365:not(#\9):focus {
  position: relative;
}
body[data-awsui-focus-visible=true] .awsui_header-button_gwq0h_yfiql_365:not(#\9):focus, body[data-awsui-focus-visible=true] .awsui_header-container-button_gwq0h_yfiql_365:not(#\9):focus {
  outline: 2px dotted transparent;
  outline-offset: calc(0px - 1px);
}
body[data-awsui-focus-visible=true] .awsui_header-button_gwq0h_yfiql_365:not(#\9):focus::before, body[data-awsui-focus-visible=true] .awsui_header-container-button_gwq0h_yfiql_365:not(#\9):focus::before {
  content: " ";
  display: block;
  position: absolute;
  inset-inline-start: calc(-1 * 0px);
  inset-block-start: calc(-1 * 0px);
  inline-size: calc(100% + 0px + 0px);
  block-size: calc(100% + 0px + 0px);
  border-start-start-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  border-start-end-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  border-end-start-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  border-end-end-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  box-shadow: 0 0 0 2px var(--color-border-item-focused-nv6mhz, #006ce0);
}
.awsui_header-button_gwq0h_yfiql_365:not(#\9) {
  box-sizing: border-box;
  display: flex;
  margin-inline-start: calc(-1 * calc(var(--size-icon-normal-0m1722, 16px) + (var(--line-height-body-m-30ar75, 20px) - var(--size-icon-normal-0m1722, 16px)) / -2 + var(--space-xxs-p8yyaw, 4px) + var(--border-divider-list-width-27y3k5, 1px)));
}
.awsui_header-container-button_gwq0h_yfiql_365:not(#\9) {
  margin-inline-start: calc(-1 * calc(var(--size-icon-medium-y9xuoq, 20px) + (var(--line-height-body-m-30ar75, 20px) - var(--size-icon-normal-0m1722, 16px)) / -2 + var(--space-xs-zb16t3, 8px)));
}
.awsui_header-container_gwq0h_yfiql_365:not(#\9) {
  inline-size: 100%;
}
.awsui_header-container_gwq0h_yfiql_365 > .awsui_icon-container_gwq0h_yfiql_264:not(#\9) {
  margin-block-start: var(--space-expandable-section-icon-offset-top-ekk5vc, 4px);
}
.awsui_header-navigation_gwq0h_yfiql_400 > .awsui_icon-container_gwq0h_yfiql_264:not(#\9) {
  display: inline-flex;
  cursor: pointer;
  color: var(--color-text-expandable-section-navigation-icon-default-tbe28s, #424650);
  border-block: 0;
  border-inline: 0;
  padding-block: 0;
  padding-inline: 0;
  background: transparent;
  outline: none;
  text-decoration: none;
  flex-direction: column;
}
.awsui_header-navigation_gwq0h_yfiql_400 > .awsui_icon-container_gwq0h_yfiql_264:not(#\9):hover {
  color: var(--color-text-expandable-section-hover-lomw6v, #006ce0);
}
body[data-awsui-focus-visible=true] .awsui_header-navigation_gwq0h_yfiql_400 > .awsui_icon-container_gwq0h_yfiql_264:not(#\9):focus {
  position: relative;
}
body[data-awsui-focus-visible=true] .awsui_header-navigation_gwq0h_yfiql_400 > .awsui_icon-container_gwq0h_yfiql_264:not(#\9):focus {
  outline: 2px dotted transparent;
  outline-offset: calc(2px - 1px);
}
body[data-awsui-focus-visible=true] .awsui_header-navigation_gwq0h_yfiql_400 > .awsui_icon-container_gwq0h_yfiql_264:not(#\9):focus::before {
  content: " ";
  display: block;
  position: absolute;
  inset-inline-start: calc(-1 * 2px);
  inset-block-start: calc(-1 * 2px);
  inline-size: calc(100% + 2px + 2px);
  block-size: calc(100% + 2px + 2px);
  border-start-start-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  border-start-end-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  border-end-start-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  border-end-end-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  box-shadow: 0 0 0 2px var(--color-border-item-focused-nv6mhz, #006ce0);
}
.awsui_header-text_gwq0h_yfiql_437:not(#\9) {
  /* used in test-utils */
}

:not(#\9):not(.awsui_wrapper-compact_gwq0h_yfiql_291) > .awsui_header-actions-wrapper_gwq0h_yfiql_359 {
  flex-wrap: wrap;
  column-gap: var(--space-xs-zb16t3, 8px);
  row-gap: var(--space-scaled-xxxs-27y4hv, 2px);
}

.awsui_content_gwq0h_yfiql_161:not(#\9) {
  display: none;
}
.awsui_content-default_gwq0h_yfiql_450:not(#\9), .awsui_content-inline_gwq0h_yfiql_450:not(#\9) {
  padding-block: var(--space-scaled-xs-26e2du, 8px);
  padding-inline: 0;
}
.awsui_content-footer_gwq0h_yfiql_454:not(#\9) {
  padding-block: var(--space-xs-zb16t3, 8px);
  padding-inline: 0;
}
.awsui_content-expanded_gwq0h_yfiql_458:not(#\9) {
  display: block;
}
.awsui_content-compact_gwq0h_yfiql_461:not(#\9) {
  padding-inline-start: calc(var(--size-icon-normal-0m1722, 16px) + (var(--line-height-body-m-30ar75, 20px) - var(--size-icon-normal-0m1722, 16px)) / -2 + var(--space-xxs-p8yyaw, 4px) + var(--border-divider-list-width-27y3k5, 1px));
}

.awsui_focusable_gwq0h_yfiql_465:not(#\9):focus {
  outline: none;
  text-decoration: none;
}
body[data-awsui-focus-visible=true] .awsui_focusable_gwq0h_yfiql_465:not(#\9):focus {
  outline: 2px dotted transparent;
  border-block: var(--border-width-field-h1g7tw, 2px) solid var(--color-border-item-focused-nv6mhz, #006ce0);
  border-inline: var(--border-width-field-h1g7tw, 2px) solid var(--color-border-item-focused-nv6mhz, #006ce0);
  border-start-start-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  border-start-end-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  border-end-start-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  border-end-end-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  box-shadow: 0 0 0 var(--border-control-focus-ring-shadow-spread-0ctpjf, 0px) var(--color-border-item-focused-nv6mhz, #006ce0);
}

.awsui_click-target_gwq0h_yfiql_480:not(#\9) {
  cursor: pointer;
}
.awsui_click-target_gwq0h_yfiql_480:not(#\9):not(.awsui_wrapper-container_gwq0h_yfiql_287):not(.awsui_header-container-button_gwq0h_yfiql_365):hover {
  color: var(--color-text-expandable-section-hover-lomw6v, #006ce0);
}