/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
.awsui_root_qk1j1_cj4a9_145:not(#\9) {
  /* used for test-utils */
}

.awsui_textarea_qk1j1_cj4a9_149:not(#\9) {
  border-collapse: separate;
  border-spacing: 0;
  box-sizing: border-box;
  caption-side: top;
  cursor: auto;
  direction: inherit;
  empty-cells: show;
  font-family: serif;
  font-style: normal;
  font-variant: normal;
  font-stretch: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: start;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  word-spacing: normal;
  font-size: var(--font-size-body-m-x4okxb, 14px);
  line-height: var(--line-height-body-m-30ar75, 20px);
  color: var(--color-text-body-default-7v1jfn, #0f141a);
  font-weight: 400;
  font-family: var(--font-family-base-dnvic8, "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  resize: auto;
  cursor: text;
  white-space: pre-wrap;
  padding-block: var(--space-scaled-xxs-7597g1, 4px);
  padding-inline: var(--space-field-horizontal-gg19kw, 12px);
  color: var(--color-text-body-default-7v1jfn, #0f141a);
  max-inline-size: 100%;
  inline-size: 100%;
  display: block;
  box-sizing: border-box;
  background-color: var(--color-background-input-default-u56ls1, #ffffff);
  border-start-start-radius: var(--border-radius-input-plgbrq, 8px);
  border-start-end-radius: var(--border-radius-input-plgbrq, 8px);
  border-end-start-radius: var(--border-radius-input-plgbrq, 8px);
  border-end-end-radius: var(--border-radius-input-plgbrq, 8px);
  border-block: var(--border-width-field-h1g7tw, 2px) solid var(--color-border-input-default-l7v83d, #8c8c94);
  border-inline: var(--border-width-field-h1g7tw, 2px) solid var(--color-border-input-default-l7v83d, #8c8c94);
  font-size: var(--font-size-body-m-x4okxb, 14px);
  line-height: var(--line-height-body-m-30ar75, 20px);
}
.awsui_textarea_qk1j1_cj4a9_149.awsui_textarea-readonly_qk1j1_cj4a9_199:not(#\9) {
  background-color: var(--color-background-input-default-u56ls1, #ffffff);
  border-block: var(--border-width-field-h1g7tw, 2px) solid var(--color-border-input-disabled-lb7zn3, #ebebf0);
  border-inline: var(--border-width-field-h1g7tw, 2px) solid var(--color-border-input-disabled-lb7zn3, #ebebf0);
}
.awsui_textarea_qk1j1_cj4a9_149:not(#\9)::placeholder {
  color: var(--color-text-input-placeholder-f3x213, #656871);
  font-style: italic;
  opacity: 1;
}
.awsui_textarea_qk1j1_cj4a9_149:not(#\9):-ms-input-placeholder {
  color: var(--color-text-input-placeholder-f3x213, #656871);
  font-style: italic;
}
.awsui_textarea_qk1j1_cj4a9_149:not(#\9):focus {
  outline: 2px dotted transparent;
  border-block: var(--border-width-field-h1g7tw, 2px) solid var(--color-border-input-focused-c6w5i5, #002b66);
  border-inline: var(--border-width-field-h1g7tw, 2px) solid var(--color-border-input-focused-c6w5i5, #002b66);
  border-start-start-radius: var(--border-radius-input-plgbrq, 8px);
  border-start-end-radius: var(--border-radius-input-plgbrq, 8px);
  border-end-start-radius: var(--border-radius-input-plgbrq, 8px);
  border-end-end-radius: var(--border-radius-input-plgbrq, 8px);
  box-shadow: 0 0 0 var(--border-control-focus-ring-shadow-spread-0ctpjf, 0px) var(--color-border-item-focused-nv6mhz, #006ce0);
}
.awsui_textarea_qk1j1_cj4a9_149:not(#\9):invalid {
  box-shadow: none;
}
.awsui_textarea_qk1j1_cj4a9_149:not(#\9):disabled {
  background-color: var(--color-background-input-disabled-gvxsk4, #ebebf0);
  border-block: var(--border-width-field-h1g7tw, 2px) solid var(--color-border-input-disabled-lb7zn3, #ebebf0);
  border-inline: var(--border-width-field-h1g7tw, 2px) solid var(--color-border-input-disabled-lb7zn3, #ebebf0);
  color: var(--color-text-input-disabled-fc4jfl, #b4b4bb);
  cursor: auto;
  cursor: default;
}
.awsui_textarea_qk1j1_cj4a9_149:not(#\9):disabled::placeholder {
  color: var(--color-text-input-placeholder-disabled-7v2f7f, #b4b4bb);
  opacity: 1;
}
.awsui_textarea_qk1j1_cj4a9_149:not(#\9):disabled:-ms-input-placeholder {
  color: var(--color-text-input-placeholder-disabled-7v2f7f, #b4b4bb);
}
.awsui_textarea_qk1j1_cj4a9_149.awsui_textarea-invalid_qk1j1_cj4a9_241:not(#\9) {
  color: var(--color-text-status-error-5676bj, #db0000);
  border-color: var(--color-text-status-error-5676bj, #db0000);
  padding-inline-start: calc(var(--space-field-horizontal-gg19kw, 12px) - (var(--border-invalid-width-5ktjp2, 8px) - var(--border-width-field-h1g7tw, 2px)));
  border-inline-start-width: var(--border-invalid-width-5ktjp2, 8px);
}
.awsui_textarea_qk1j1_cj4a9_149.awsui_textarea-invalid_qk1j1_cj4a9_241:not(#\9):focus {
  box-shadow: 0 0 0 var(--border-control-invalid-focus-ring-shadow-spread-fhmbjn, 2px) var(--color-border-item-focused-nv6mhz, #006ce0);
}
.awsui_textarea_qk1j1_cj4a9_149.awsui_textarea-warning_qk1j1_cj4a9_250:not(#\9) {
  color: var(--color-text-status-warning-csaw41, #855900);
  border-color: var(--color-text-status-warning-csaw41, #855900);
  padding-inline-start: calc(var(--space-field-horizontal-gg19kw, 12px) - (var(--border-invalid-width-5ktjp2, 8px) - var(--border-width-field-h1g7tw, 2px)));
  border-inline-start-width: var(--border-invalid-width-5ktjp2, 8px);
}
.awsui_textarea_qk1j1_cj4a9_149.awsui_textarea-warning_qk1j1_cj4a9_250:not(#\9):focus {
  box-shadow: 0 0 0 var(--border-control-invalid-focus-ring-shadow-spread-fhmbjn, 2px) var(--color-border-item-focused-nv6mhz, #006ce0);
}