/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
.awsui_root_l0dv0_1qapx_145:not(#\9) {
  border-collapse: separate;
  border-spacing: 0;
  box-sizing: border-box;
  caption-side: top;
  cursor: auto;
  direction: inherit;
  empty-cells: show;
  font-family: serif;
  font-style: normal;
  font-variant: normal;
  font-stretch: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: start;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  word-spacing: normal;
  font-size: var(--font-size-body-m-x4okxb, 14px);
  line-height: var(--line-height-body-m-30ar75, 20px);
  color: var(--color-text-body-default-7v1jfn, #0f141a);
  font-weight: 400;
  font-family: var(--font-family-base-dnvic8, "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  min-inline-size: 0;
  word-break: break-word;
}

.awsui_header_l0dv0_1qapx_179:not(#\9) {
  font-size: var(--font-panel-header-size-edjz3l, 18px);
  line-height: var(--font-panel-header-line-height-kxi4u2, 22px);
  font-weight: var(--font-weight-heading-l-f8711v, 700);
  -webkit-font-smoothing: var(--font-smoothing-webkit-m8nc84, antialiased);
  -moz-osx-font-smoothing: var(--font-smoothing-moz-osx-foywby, grayscale);
  margin-block: 0;
  margin-inline: 0;
  padding-block: var(--space-panel-header-vertical-gv9pti, 20px);
  padding-inline-start: var(--space-panel-nav-left-b1g0mt, 28px);
  padding-inline-end: calc(var(--space-scaled-xxl-wbot5q, 32px) + var(--space-xl-lmui9r, 24px));
}

.awsui_header-link_l0dv0_1qapx_192:not(#\9) {
  font-size: var(--font-panel-header-size-edjz3l, 18px);
  line-height: var(--font-panel-header-line-height-kxi4u2, 22px);
  font-weight: var(--font-weight-heading-l-f8711v, 700);
  -webkit-font-smoothing: var(--font-smoothing-webkit-m8nc84, antialiased);
  -moz-osx-font-smoothing: var(--font-smoothing-moz-osx-foywby, grayscale);
  color: var(--color-text-heading-default-jen9ei, #0f141a);
  min-block-size: var(--font-panel-header-line-height-kxi4u2, 22px);
  display: flex;
}
.awsui_header-link--has-logo_l0dv0_1qapx_202 > .awsui_header-link-text_l0dv0_1qapx_202:not(#\9) {
  font-weight: 400;
  align-self: center;
}

.awsui_header-logo_l0dv0_1qapx_207:not(#\9) {
  margin-inline-end: var(--space-s-34lx8l, 12px);
  margin-block-start: var(--space-xxxs-zbmxqb, 2px);
  max-inline-size: calc(1.25 * var(--size-icon-big-zkdryd, 32px));
  align-self: flex-start;
}
.awsui_header-logo--stretched_l0dv0_1qapx_213:not(#\9) {
  max-inline-size: 100%;
  margin-inline-end: 0;
}

.awsui_items-control_l0dv0_1qapx_218:not(#\9) {
  padding-inline: var(--space-l-t419sm, 20px);
}

.awsui_list-container_l0dv0_1qapx_222:not(#\9) {
  margin-block-end: var(--space-panel-content-bottom-7k6er3, 40px);
}

.awsui_items-control_l0dv0_1qapx_218:not(#\9),
.awsui_list-container_l0dv0_1qapx_222:not(#\9) {
  margin-block-start: var(--space-panel-content-top-wac3yp, 20px);
}
.awsui_with-toolbar_l0dv0_1qapx_230 > .awsui_divider-header_l0dv0_1qapx_230 + .awsui_items-control_l0dv0_1qapx_218:not(#\9),
.awsui_with-toolbar_l0dv0_1qapx_230 > .awsui_divider-header_l0dv0_1qapx_230 + .awsui_list-container_l0dv0_1qapx_222:not(#\9) {
  margin-block-start: 0;
}

.awsui_list_l0dv0_1qapx_222:not(#\9) {
  margin-block: 0;
  margin-inline: 0;
  padding-block: 0;
  padding-inline-end: 0;
  padding-inline-start: var(--space-l-t419sm, 20px);
}

.awsui_list-variant-root_l0dv0_1qapx_243:not(#\9) {
  margin-block: 0;
  margin-inline: 0;
  padding-block: 0;
  padding-inline-start: var(--space-panel-nav-left-b1g0mt, 28px);
  padding-inline-end: var(--space-panel-side-right-xofg51, 24px);
}
.awsui_list-variant-root--first_l0dv0_1qapx_250:not(#\9) {
  margin-block-start: 0;
}

.awsui_list-variant-expandable-link-group_l0dv0_1qapx_254:not(#\9) {
  padding-inline-start: var(--space-xxxl-4x2gki, 40px);
}

.awsui_list-item_l0dv0_1qapx_258:not(#\9) {
  margin-block: var(--space-scaled-xs-26e2du, 8px);
  margin-inline: 0;
  padding-block: 0;
  padding-inline: 0;
  list-style: none;
}
.awsui_list-variant-root--first_l0dv0_1qapx_250 > .awsui_list-item_l0dv0_1qapx_258:not(#\9):first-child {
  margin-block-start: 0px;
}

.awsui_section_l0dv0_1qapx_269:not(#\9),
.awsui_expandable-link-group_l0dv0_1qapx_270:not(#\9) {
  margin-inline-start: calc(-1 * var(--space-l-t419sm, 20px));
}
.awsui_section--no-ident_l0dv0_1qapx_273:not(#\9),
.awsui_expandable-link-group--no-ident_l0dv0_1qapx_274:not(#\9) {
  margin-inline-start: 0;
}

.awsui_section_l0dv0_1qapx_269:not(#\9) {
  margin-block: calc(var(--space-scaled-2x-l-ujnz41, 20px) - var(--border-divider-section-width-1061zr, 1px));
  /* stylelint-disable-next-line selector-max-type */
}
.awsui_section_l0dv0_1qapx_269.awsui_refresh_l0dv0_1qapx_282:not(#\9) {
  margin-block: calc(var(--space-scaled-2x-m-owtj1o, 16px) - var(--border-divider-section-width-1061zr, 1px));
}
.awsui_list-variant-root--first_l0dv0_1qapx_250 > .awsui_list-item_l0dv0_1qapx_258:not(#\9):first-child > .awsui_section_l0dv0_1qapx_269 {
  margin-block-start: 0px;
}
.awsui_section_l0dv0_1qapx_269 > div:not(#\9) {
  padding-block: 0;
  padding-inline: 0;
}

.awsui_list-variant-section-group_l0dv0_1qapx_293:not(#\9) {
  margin-block: 0;
  margin-inline: 0;
  padding-block: 0;
  padding-inline: 0;
}

.awsui_section-group_l0dv0_1qapx_300:not(#\9) {
  font-size: var(--font-size-heading-m-sjd256, 18px);
  line-height: var(--line-height-heading-m-50evfk, 22px);
  letter-spacing: var(--letter-spacing-heading-m-0w575i, -0.01em);
  font-weight: var(--font-weight-heading-m-m2ekmb, 700);
  -webkit-font-smoothing: var(--font-smoothing-webkit-m8nc84, antialiased);
  -moz-osx-font-smoothing: var(--font-smoothing-moz-osx-foywby, grayscale);
  margin-block: 0;
  margin-inline: 0;
}

.awsui_section-group-title_l0dv0_1qapx_311:not(#\9) {
  /* used in test-utils */
}

.awsui_link_l0dv0_1qapx_315:not(#\9) {
  font-size: var(--font-size-body-m-x4okxb, 14px);
  line-height: var(--line-height-body-m-30ar75, 20px);
  color: var(--color-text-body-secondary-cwla8d, #424650);
  font-weight: 400;
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}

.awsui_link-active_l0dv0_1qapx_324:not(#\9) {
  font-weight: var(--font-wayfinding-link-active-weight-rbmzei, 700);
  -webkit-font-smoothing: var(--font-smoothing-webkit-m8nc84, antialiased);
  -moz-osx-font-smoothing: var(--font-smoothing-moz-osx-foywby, grayscale);
  color: var(--color-text-accent-fptgkc, #006ce0);
}

.awsui_header-link_l0dv0_1qapx_192:not(#\9),
.awsui_link_l0dv0_1qapx_315:not(#\9) {
  text-decoration: none;
}
.awsui_header-link_l0dv0_1qapx_192:not(#\9):hover,
.awsui_link_l0dv0_1qapx_315:not(#\9):hover {
  color: var(--color-text-accent-fptgkc, #006ce0);
}
.awsui_header-link_l0dv0_1qapx_192:not(#\9):focus,
.awsui_link_l0dv0_1qapx_315:not(#\9):focus {
  outline: none;
}
.awsui_header-link_l0dv0_1qapx_192:not(#\9):hover, .awsui_header-link_l0dv0_1qapx_192:not(#\9):focus, .awsui_link_l0dv0_1qapx_315:not(#\9):hover, .awsui_link_l0dv0_1qapx_315:not(#\9):focus {
  text-decoration: none;
}
body[data-awsui-focus-visible=true] .awsui_header-link_l0dv0_1qapx_192:not(#\9):focus,
body[data-awsui-focus-visible=true] .awsui_link_l0dv0_1qapx_315:not(#\9):focus {
  outline: thin dotted;
  outline: var(--border-link-focus-ring-outline-kkfop6, 0);
  outline-offset: 2px;
  outline-color: var(--color-border-item-focused-nv6mhz, #006ce0);
  border-start-start-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  border-start-end-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  border-end-start-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  border-end-end-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  box-shadow: 0 0 0 var(--border-link-focus-ring-shadow-spread-v8hkrl, 2px) var(--color-border-item-focused-nv6mhz, #006ce0);
}

.awsui_info_l0dv0_1qapx_361:not(#\9) {
  margin-inline-start: var(--space-xs-zb16t3, 8px);
}

.awsui_external-icon_l0dv0_1qapx_365:not(#\9) {
  margin-inline-start: var(--space-xxs-p8yyaw, 4px);
}

.awsui_divider_l0dv0_1qapx_230:not(#\9) {
  border-block: none;
  border-inline: none;
}

.awsui_divider-default_l0dv0_1qapx_374:not(#\9) {
  margin-block: var(--space-scaled-2x-xl-udij7m, 24px);
  margin-inline: calc(-1 * var(--space-panel-divider-margin-horizontal-sbik1z, 8px));
  border-block-start: var(--border-divider-section-width-1061zr, 1px) solid var(--color-border-divider-default-cx07f2, #c6c6cd);
}

.awsui_divider-header_l0dv0_1qapx_230:not(#\9) {
  margin-block: 0;
  border-block-start: var(--border-divider-section-width-1061zr, 1px) solid var(--color-border-panel-header-nnst86, #c6c6cd);
}
.awsui_with-toolbar_l0dv0_1qapx_230 > .awsui_divider-header_l0dv0_1qapx_230:not(#\9) {
  border-color: transparent;
}