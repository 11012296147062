/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_empty-appear_n4qlp_1nsib_153:not(#\9) {
  animation: awsui_awsui-motion-fade-in_n4qlp_1nsib_1 var(--motion-duration-transition-show-paced-9s9l5z, 180ms) var(--motion-easing-transition-show-paced-eobwvi, ease-out);
}
@keyframes awsui_awsui-motion-fade-in_n4qlp_1nsib_1 {
  from {
    opacity: 0.2;
  }
  to {
    opacity: 1;
  }
}
@media (prefers-reduced-motion: reduce) {
  .awsui_empty-appear_n4qlp_1nsib_153:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_empty-appear_n4qlp_1nsib_153:not(#\9), .awsui-mode-entering .awsui_empty-appear_n4qlp_1nsib_153:not(#\9) {
  animation: none;
  transition: none;
}

.awsui_root_n4qlp_1nsib_175:not(#\9) {
  border-collapse: separate;
  border-spacing: 0;
  box-sizing: border-box;
  caption-side: top;
  cursor: auto;
  direction: inherit;
  empty-cells: show;
  font-family: serif;
  font-style: normal;
  font-variant: normal;
  font-stretch: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: start;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  word-spacing: normal;
  font-size: var(--font-size-body-m-x4okxb, 14px);
  line-height: var(--line-height-body-m-30ar75, 20px);
  color: var(--color-text-body-default-7v1jfn, #0f141a);
  font-weight: 400;
  font-family: var(--font-family-base-dnvic8, "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  display: block;
}

.awsui_empty_n4qlp_1nsib_153:not(#\9) {
  font-size: var(--font-size-body-m-x4okxb, 14px);
  line-height: var(--line-height-body-m-30ar75, 20px);
  color: var(--color-text-empty-o6660v, #656871);
}

.awsui_row_n4qlp_1nsib_214:not(#\9) {
  /* used in test-utils */
}

.awsui_row-control_n4qlp_1nsib_218:not(#\9) {
  /* used in test-utils */
}

.awsui_field_n4qlp_1nsib_222:not(#\9) {
  /* used in test-utils */
}

.awsui_add-button_n4qlp_1nsib_226:not(#\9) {
  /* used in test-utils */
}

.awsui_remove-button_n4qlp_1nsib_230:not(#\9) {
  /* used in test-utils */
}

.awsui_button-container-haslabel_n4qlp_1nsib_234:not(#\9) {
  padding-block-start: calc(var(--space-xxs-p8yyaw, 4px) + var(--line-height-body-m-30ar75, 20px));
}

.awsui_button-container-nolabel_n4qlp_1nsib_238:not(#\9) {
  padding-block-start: var(--space-xxs-p8yyaw, 4px);
}

.awsui_divider_n4qlp_1nsib_242:not(#\9) {
  border-block-end: var(--border-divider-section-width-1061zr, 1px) solid var(--color-border-divider-default-cx07f2, #c6c6cd);
}

.awsui_additional-info_n4qlp_1nsib_246:not(#\9) {
  color: var(--color-text-form-secondary-54emib, #656871);
  font-size: var(--font-size-body-s-asqx2i, 12px);
  line-height: var(--line-height-body-s-7zv1j5, 16px);
  letter-spacing: var(--letter-spacing-body-s-z9jkwp, 0.005em);
  display: block;
  word-wrap: break-word;
  margin-block-start: var(--space-xxs-p8yyaw, 4px);
  /* stylelint-disable-next-line selector-max-type */
}
.awsui_additional-info_n4qlp_1nsib_246 > a:not(#\9) {
  text-underline-offset: 0.3em;
  text-decoration-thickness: 1px;
  color: var(--color-text-link-default-enwvrt, #006ce0);
  font-weight: inherit;
  letter-spacing: normal;
  text-decoration-line: underline;
  text-decoration-color: currentColor;
  transition-property: color, -webkit-text-decoration;
  transition-property: color, text-decoration;
  transition-property: color, text-decoration, -webkit-text-decoration;
  transition-duration: var(--motion-duration-refresh-only-medium-nf6485, 165ms);
}
@media (prefers-reduced-motion: reduce) {
  .awsui_additional-info_n4qlp_1nsib_246 > a:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_additional-info_n4qlp_1nsib_246 > a:not(#\9), .awsui-mode-entering .awsui_additional-info_n4qlp_1nsib_246 > a:not(#\9) {
  animation: none;
  transition: none;
}
.awsui_additional-info_n4qlp_1nsib_246 > a:not(#\9):hover {
  cursor: pointer;
  color: var(--color-text-link-hover-49uoe9, #002b66);
}
.awsui_additional-info_n4qlp_1nsib_246 > a:not(#\9):focus {
  outline: none;
}
.awsui_additional-info_n4qlp_1nsib_246 > a:not(#\9):active {
  color: var(--color-text-link-hover-49uoe9, #002b66);
}
.awsui_additional-info_n4qlp_1nsib_246 > a:not(#\9):active, .awsui_additional-info_n4qlp_1nsib_246 > a:not(#\9):focus, .awsui_additional-info_n4qlp_1nsib_246 > a:not(#\9):hover {
  text-decoration-line: underline;
  text-decoration-color: currentColor;
}

.awsui_right-align_n4qlp_1nsib_292:not(#\9) {
  display: flex;
  justify-content: flex-end;
}