/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_calendar_1ykar_13z93_153:not(#\9) {
  animation: awsui_awsui-motion-fade-in-0_1ykar_13z93_1 var(--motion-duration-show-quick-aq3ump, 135ms) var(--motion-easing-show-quick-vc5zgd, ease-out);
  animation-fill-mode: both;
}
@keyframes awsui_awsui-motion-fade-in-0_1ykar_13z93_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@media (prefers-reduced-motion: reduce) {
  .awsui_calendar_1ykar_13z93_153:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_calendar_1ykar_13z93_153:not(#\9), .awsui-mode-entering .awsui_calendar_1ykar_13z93_153:not(#\9) {
  animation: none;
  transition: none;
}

/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_root_1ykar_13z93_180:not(#\9) {
  /* used in test-utils */
}

.awsui_calendar_1ykar_13z93_153:not(#\9) {
  border-collapse: separate;
  border-spacing: 0;
  box-sizing: border-box;
  caption-side: top;
  cursor: auto;
  direction: inherit;
  empty-cells: show;
  font-family: serif;
  font-style: normal;
  font-variant: normal;
  font-stretch: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: start;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  word-spacing: normal;
  font-size: var(--font-size-body-m-x4okxb, 14px);
  line-height: var(--line-height-body-m-30ar75, 20px);
  color: var(--color-text-body-default-7v1jfn, #0f141a);
  font-weight: 400;
  font-family: var(--font-family-base-dnvic8, "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  display: block;
  inline-size: var(--size-calendar-grid-width-lrunz7, 238px);
  overflow: auto;
}
.awsui_calendar-inner_1ykar_13z93_218:not(#\9) {
  margin-block: var(--space-xs-zb16t3, 8px);
  margin-inline: var(--space-xs-zb16t3, 8px);
}
.awsui_calendar-header_1ykar_13z93_222:not(#\9) {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.awsui_calendar-header-title_1ykar_13z93_227:not(#\9) {
  font-size: var(--font-size-body-m-x4okxb, 14px);
  line-height: var(--line-height-body-m-30ar75, 20px);
  font-weight: 700;
  color: var(--color-text-dropdown-item-default-wpxsrk, #0f141a);
  margin-block: 0;
  margin-inline: 0;
}
.awsui_calendar-next-btn_1ykar_13z93_235:not(#\9) {
  /* used for identifying element */
}
.awsui_calendar-prev-btn_1ykar_13z93_238:not(#\9) {
  /* used for identifying element */
}
.awsui_calendar-grid_1ykar_13z93_241:not(#\9) {
  inline-size: 100%;
  table-layout: fixed;
}
.awsui_calendar-grid_1ykar_13z93_241:not(#\9):not(.awsui_calendar-grid-dense_1ykar_13z93_245) {
  border-spacing: var(--space-calendar-grid-gutter-2jsrps, 6px);
  padding-block: var(--space-xs-zb16t3, 8px);
  padding-inline: var(--space-xs-zb16t3, 8px);
}
.awsui_calendar-grid-dense_1ykar_13z93_245:not(#\9) {
  border-spacing: 0;
}
.awsui_calendar-grid-cell_1ykar_13z93_253:not(#\9) {
  word-break: break-word;
  text-align: center;
  font-weight: unset;
}
.awsui_calendar-date-header_1ykar_13z93_258:not(#\9) {
  padding-block-start: var(--space-s-34lx8l, 12px);
  padding-block-end: var(--space-xxs-p8yyaw, 4px);
  padding-inline: 0;
  color: var(--color-text-calendar-month-7dq80e, #656871);
  font-size: var(--font-size-body-s-asqx2i, 12px);
  line-height: var(--line-height-body-s-7zv1j5, 16px);
  letter-spacing: var(--letter-spacing-body-s-z9jkwp, 0.005em);
}
.awsui_calendar-date_1ykar_13z93_258:not(#\9) {
  border-block-end: 1px solid var(--color-border-calendar-grid-u86q1u, transparent);
  border-inline-end: 1px solid var(--color-border-calendar-grid-u86q1u, transparent);
  padding-block: var(--space-xxs-p8yyaw, 4px);
  padding-inline: 0;
  color: var(--color-text-dropdown-item-disabled-7fg5u8, #b4b4bb);
  position: relative;
}
.awsui_calendar-date_1ykar_13z93_258:not(#\9):first-child {
  border-inline-start: 1px solid var(--color-border-calendar-grid-u86q1u, transparent);
}
.awsui_calendar-date-enabled_1ykar_13z93_278:not(#\9) {
  cursor: pointer;
  color: var(--color-text-dropdown-item-secondary-xvh5zo, #656871);
}
.awsui_calendar-date-enabled_1ykar_13z93_278:not(#\9)::after {
  border-start-start-radius: var(--border-radius-item-05df9h, 8px);
  border-start-end-radius: var(--border-radius-item-05df9h, 8px);
  border-end-start-radius: var(--border-radius-item-05df9h, 8px);
  border-end-end-radius: var(--border-radius-item-05df9h, 8px);
}
.awsui_calendar-date-enabled_1ykar_13z93_278.awsui_calendar-date-current-page_1ykar_13z93_288:not(#\9) {
  color: var(--color-text-dropdown-item-default-wpxsrk, #0f141a);
}
.awsui_calendar-date-enabled_1ykar_13z93_278.awsui_calendar-date-current-page_1ykar_13z93_288:not(#\9):hover {
  border-start-start-radius: var(--border-radius-item-05df9h, 8px);
  border-start-end-radius: var(--border-radius-item-05df9h, 8px);
  border-end-start-radius: var(--border-radius-item-05df9h, 8px);
  border-end-end-radius: var(--border-radius-item-05df9h, 8px);
  color: var(--color-text-calendar-date-hover-qiity9, #0f141a);
  background-color: var(--color-background-dropdown-item-hover-zhzc84, #f3f3f7);
}
.awsui_calendar-date-enabled_1ykar_13z93_278.awsui_calendar-date-current-page_1ykar_13z93_288:not(#\9):hover:not(.awsui_calendar-date-selected_1ykar_13z93_299)::after {
  border-block: var(--border-item-width-yel47s, 2px) solid var(--color-border-dropdown-item-hover-4p0gsi, #8c8c94);
  border-inline: var(--border-item-width-yel47s, 2px) solid var(--color-border-dropdown-item-hover-4p0gsi, #8c8c94);
}
.awsui_calendar-date-current_1ykar_13z93_288:not(#\9) {
  border-start-start-radius: var(--border-radius-item-05df9h, 8px);
  border-start-end-radius: var(--border-radius-item-05df9h, 8px);
  border-end-start-radius: var(--border-radius-item-05df9h, 8px);
  border-end-end-radius: var(--border-radius-item-05df9h, 8px);
  background-color: var(--color-background-calendar-current-date-a6ucrp, #f3f3f7);
  font-weight: 700;
}
.awsui_calendar-date_1ykar_13z93_258:not(#\9)::after {
  content: "";
  position: absolute;
  z-index: 1;
  background-color: transparent;
  inset-block-start: calc(-1 * var(--border-item-width-yel47s, 2px));
  inset-inline-start: calc(-1 * var(--border-item-width-yel47s, 2px));
  inset-inline-end: calc(-1 * var(--border-item-width-yel47s, 2px));
}
.awsui_calendar-date_1ykar_13z93_258:not(#\9):not(.awsui_calendar-date-dense_1ykar_13z93_320)::after {
  inset-block-end: calc(-1 * var(--border-item-width-yel47s, 2px));
  inset-inline-start: calc(-1 * var(--border-item-width-yel47s, 2px));
}
.awsui_calendar-date-dense_1ykar_13z93_320:not(#\9)::after {
  inset-block-end: -1px;
  inset-inline-start: -1px;
}
.awsui_calendar-date_1ykar_13z93_258 > .awsui_date-inner_1ykar_13z93_328:not(#\9) {
  position: relative;
  z-index: 1;
}
.awsui_calendar-date_1ykar_13z93_258:not(#\9):focus {
  outline: none;
}
body[data-awsui-focus-visible=true] .awsui_calendar-date_1ykar_13z93_258:not(#\9):focus:focus {
  position: relative;
}
body[data-awsui-focus-visible=true] .awsui_calendar-date_1ykar_13z93_258:not(#\9):focus:focus {
  outline: 2px dotted transparent;
  outline-offset: calc(var(--space-calendar-grid-focus-outline-gutter-g00bgj, -5px) - 1px);
}
body[data-awsui-focus-visible=true] .awsui_calendar-date_1ykar_13z93_258:not(#\9):focus:focus::before {
  content: " ";
  display: block;
  position: absolute;
  inset-inline-start: calc(-1 * var(--space-calendar-grid-focus-outline-gutter-g00bgj, -5px));
  inset-block-start: calc(-1 * var(--space-calendar-grid-focus-outline-gutter-g00bgj, -5px));
  inline-size: calc(100% + var(--space-calendar-grid-focus-outline-gutter-g00bgj, -5px) + var(--space-calendar-grid-focus-outline-gutter-g00bgj, -5px));
  block-size: calc(100% + var(--space-calendar-grid-focus-outline-gutter-g00bgj, -5px) + var(--space-calendar-grid-focus-outline-gutter-g00bgj, -5px));
  border-start-start-radius: var(--border-radius-calendar-day-focus-ring-545h0w, 3px);
  border-start-end-radius: var(--border-radius-calendar-day-focus-ring-545h0w, 3px);
  border-end-start-radius: var(--border-radius-calendar-day-focus-ring-545h0w, 3px);
  border-end-end-radius: var(--border-radius-calendar-day-focus-ring-545h0w, 3px);
  box-shadow: 0 0 0 2px var(--color-border-item-focused-nv6mhz, #006ce0);
}
body[data-awsui-focus-visible=true] .awsui_calendar-date_1ykar_13z93_258:not(#\9):focus:focus::before {
  z-index: 2;
}
.awsui_calendar-date-selected_1ykar_13z93_299:not(#\9) {
  border-color: transparent;
  position: relative;
  z-index: 2;
  font-weight: 700;
}
body[data-awsui-focus-visible=true] .awsui_calendar-date-selected_1ykar_13z93_299:not(#\9):focus:focus {
  position: relative;
}
body[data-awsui-focus-visible=true] .awsui_calendar-date-selected_1ykar_13z93_299:not(#\9):focus:focus {
  outline: 2px dotted transparent;
  outline-offset: calc(var(--space-calendar-grid-focus-outline-gutter-g00bgj, -5px) - 1px);
}
body[data-awsui-focus-visible=true] .awsui_calendar-date-selected_1ykar_13z93_299:not(#\9):focus:focus::before {
  content: " ";
  display: block;
  position: absolute;
  inset-inline-start: calc(-1 * var(--space-calendar-grid-focus-outline-gutter-g00bgj, -5px));
  inset-block-start: calc(-1 * var(--space-calendar-grid-focus-outline-gutter-g00bgj, -5px));
  inline-size: calc(100% + var(--space-calendar-grid-focus-outline-gutter-g00bgj, -5px) + var(--space-calendar-grid-focus-outline-gutter-g00bgj, -5px));
  block-size: calc(100% + var(--space-calendar-grid-focus-outline-gutter-g00bgj, -5px) + var(--space-calendar-grid-focus-outline-gutter-g00bgj, -5px));
  border-start-start-radius: var(--border-radius-calendar-day-focus-ring-545h0w, 3px);
  border-start-end-radius: var(--border-radius-calendar-day-focus-ring-545h0w, 3px);
  border-end-start-radius: var(--border-radius-calendar-day-focus-ring-545h0w, 3px);
  border-end-end-radius: var(--border-radius-calendar-day-focus-ring-545h0w, 3px);
  box-shadow: 0 0 0 2px var(--color-border-calendar-grid-selected-focus-ring-w7gc9h, #f9f9fa);
}
body[data-awsui-focus-visible=true] .awsui_calendar-date-selected_1ykar_13z93_299:not(#\9):focus:focus::before {
  z-index: 2;
}
.awsui_calendar-date-selected_1ykar_13z93_299:not(#\9)::after {
  border-start-start-radius: var(--border-radius-item-05df9h, 8px);
  border-start-end-radius: var(--border-radius-item-05df9h, 8px);
  border-end-start-radius: var(--border-radius-item-05df9h, 8px);
  border-end-end-radius: var(--border-radius-item-05df9h, 8px);
  background-color: var(--color-background-control-checked-hz4k40, #006ce0);
  border-block: var(--border-item-width-yel47s, 2px) solid var(--color-background-control-checked-hz4k40, #006ce0);
  border-inline: var(--border-item-width-yel47s, 2px) solid var(--color-background-control-checked-hz4k40, #006ce0);
}
.awsui_calendar-date-selected_1ykar_13z93_299 > .awsui_date-inner_1ykar_13z93_328:not(#\9) {
  z-index: 2;
  color: var(--color-background-control-default-5ol8rv, #ffffff);
  position: relative;
}
.awsui_calendar-row_1ykar_13z93_403:not(#\9):first-child > .awsui_calendar-date_1ykar_13z93_258 {
  border-block-start: 1px solid var(--color-border-calendar-grid-u86q1u, transparent);
}

.awsui_disabled-reason-tooltip_1ykar_13z93_407:not(#\9) {
  /* used in test-utils or tests */
}