/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
.awsui_badge_1yjyg_wvd6p_145:not(#\9) {
  border-collapse: separate;
  border-spacing: 0;
  box-sizing: border-box;
  caption-side: top;
  cursor: auto;
  direction: inherit;
  empty-cells: show;
  font-family: serif;
  font-style: normal;
  font-variant: normal;
  font-stretch: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: start;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  word-spacing: normal;
  font-size: var(--font-size-body-m-x4okxb, 14px);
  line-height: var(--line-height-body-m-30ar75, 20px);
  color: var(--color-text-body-default-7v1jfn, #0f141a);
  font-weight: 400;
  font-family: var(--font-family-base-dnvic8, "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  font-size: var(--font-size-body-s-asqx2i, 12px);
  line-height: var(--line-height-body-s-7zv1j5, 16px);
  letter-spacing: var(--letter-spacing-body-s-z9jkwp, 0.005em);
  line-height: var(--line-height-body-m-30ar75, 20px);
  display: inline-block;
  border-start-start-radius: var(--border-radius-badge-0z09dt, 4px);
  border-start-end-radius: var(--border-radius-badge-0z09dt, 4px);
  border-end-start-radius: var(--border-radius-badge-0z09dt, 4px);
  border-end-end-radius: var(--border-radius-badge-0z09dt, 4px);
  padding-block: 0;
  padding-inline: var(--space-xs-zb16t3, 8px);
  color: var(--color-text-notification-default-0l2rzu, #f9f9fa);
}
.awsui_badge_1yjyg_wvd6p_145.awsui_badge-color-grey_1yjyg_wvd6p_188:not(#\9) {
  background-color: var(--color-background-notification-grey-jyn3j2, #424650);
}
.awsui_badge_1yjyg_wvd6p_145.awsui_badge-color-green_1yjyg_wvd6p_191:not(#\9) {
  background-color: var(--color-background-notification-green-7zevmb, #00802f);
}
.awsui_badge_1yjyg_wvd6p_145.awsui_badge-color-blue_1yjyg_wvd6p_194:not(#\9) {
  background-color: var(--color-background-notification-blue-g87klk, #006ce0);
}
.awsui_badge_1yjyg_wvd6p_145.awsui_badge-color-red_1yjyg_wvd6p_197:not(#\9) {
  background-color: var(--color-background-notification-red-f0cy22, #db0000);
}
.awsui_badge_1yjyg_wvd6p_145.awsui_badge-color-severity-critical_1yjyg_wvd6p_200:not(#\9) {
  background-color: var(--color-background-notification-severity-critical-m6z340, #870303);
  color: var(--color-text-notification-severity-critical-cmj0dk, #f9f9fa);
}
.awsui_badge_1yjyg_wvd6p_145.awsui_badge-color-severity-high_1yjyg_wvd6p_204:not(#\9) {
  background-color: var(--color-background-notification-severity-high-zn7yde, #ce3311);
  color: var(--color-text-notification-severity-high-rgh0m2, #f9f9fa);
}
.awsui_badge_1yjyg_wvd6p_145.awsui_badge-color-severity-medium_1yjyg_wvd6p_208:not(#\9) {
  background-color: var(--color-background-notification-severity-medium-empymi, #f89256);
  color: var(--color-text-notification-severity-medium-9rk0hm, #0f141a);
}
.awsui_badge_1yjyg_wvd6p_145.awsui_badge-color-severity-low_1yjyg_wvd6p_212:not(#\9) {
  background-color: var(--color-background-notification-severity-low-jkxbp4, #f2cd54);
  color: var(--color-text-notification-severity-low-so8wqb, #0f141a);
}
.awsui_badge_1yjyg_wvd6p_145.awsui_badge-color-severity-neutral_1yjyg_wvd6p_216:not(#\9) {
  background-color: var(--color-background-notification-severity-neutral-wtuxqz, #656871);
  color: var(--color-text-notification-severity-neutral-h04hdx, #f9f9fa);
}