/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
.awsui_top-navigation_k5dlb_1c946_145:not(#\9) {
  border-collapse: separate;
  border-spacing: 0;
  box-sizing: border-box;
  caption-side: top;
  cursor: auto;
  direction: inherit;
  empty-cells: show;
  font-family: serif;
  font-style: normal;
  font-variant: normal;
  font-stretch: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: start;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  word-spacing: normal;
  font-size: var(--font-size-body-m-x4okxb, 14px);
  line-height: var(--line-height-body-m-30ar75, 20px);
  color: var(--color-text-body-default-7v1jfn, #0f141a);
  font-weight: 400;
  font-family: var(--font-family-base-dnvic8, "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  background: var(--color-background-container-content-z79u4u, #ffffff);
}
.awsui_top-navigation_k5dlb_1c946_145 > .awsui_padding-box_k5dlb_1c946_177:not(#\9) {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  block-size: calc(var(--space-xxxl-4x2gki, 40px) + var(--space-scaled-m-mo5yse, 16px));
  padding-inline-start: var(--space-xxl-cu2m1r, 32px);
}
.awsui_top-navigation_k5dlb_1c946_145.awsui_medium_k5dlb_1c946_187 > .awsui_padding-box_k5dlb_1c946_177:not(#\9), .awsui_top-navigation_k5dlb_1c946_145.awsui_narrow_k5dlb_1c946_187 > .awsui_padding-box_k5dlb_1c946_177:not(#\9) {
  padding-inline-start: var(--space-l-t419sm, 20px);
}
.awsui_top-navigation_k5dlb_1c946_145.awsui_medium_k5dlb_1c946_187 > .awsui_padding-box_k5dlb_1c946_177:not(#\9) {
  block-size: calc(var(--space-xxxl-4x2gki, 40px) + var(--space-scaled-xs-26e2du, 8px));
  padding-inline-end: 0;
}
.awsui_top-navigation_k5dlb_1c946_145.awsui_narrow_k5dlb_1c946_187 > .awsui_padding-box_k5dlb_1c946_177:not(#\9) {
  block-size: var(--space-xxxl-4x2gki, 40px);
}

.awsui_virtual_k5dlb_1c946_198:not(#\9) {
  inline-size: 9000px;
}

.awsui_hidden_k5dlb_1c946_202:not(#\9) {
  position: absolute !important;
  inset-block-start: -9999px !important;
  inset-inline-start: -9999px !important;
  visibility: hidden;
}

/* stylelint-disable-next-line selector-combinator-disallowed-list, selector-max-universal */
.awsui_hidden_k5dlb_1c946_202 *:not(#\9):not(#awsui_\9 _k5dlb_1c946_1) {
  visibility: hidden;
}

.awsui_identity_k5dlb_1c946_214:not(#\9) {
  min-inline-size: 0;
}
.awsui_identity_k5dlb_1c946_214 > .awsui_identity-link_k5dlb_1c946_217:not(#\9) {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--color-text-top-navigation-title-v9cb3m, #0f141a);
}
.awsui_identity_k5dlb_1c946_214 > .awsui_identity-link_k5dlb_1c946_217:not(#\9):hover {
  color: var(--color-text-accent-fptgkc, #006ce0);
}
body[data-awsui-focus-visible=true] .awsui_identity_k5dlb_1c946_214 > .awsui_identity-link_k5dlb_1c946_217:not(#\9):focus {
  outline: thin dotted;
  outline: var(--border-link-focus-ring-outline-kkfop6, 0);
  outline-offset: 2px;
  outline-color: var(--color-border-item-focused-nv6mhz, #006ce0);
  border-start-start-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  border-start-end-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  border-end-start-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  border-end-end-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  box-shadow: 0 0 0 var(--border-link-focus-ring-shadow-spread-v8hkrl, 2px) var(--color-border-item-focused-nv6mhz, #006ce0);
}
.awsui_identity_k5dlb_1c946_214.awsui_no-logo_k5dlb_1c946_237:not(#\9) {
  min-inline-size: 100px;
}

.awsui_logo_k5dlb_1c946_241:not(#\9) {
  display: block;
  max-block-size: var(--space-xxl-cu2m1r, 32px);
  margin-inline-end: var(--space-s-34lx8l, 12px);
  inline-size: auto;
  flex-shrink: 0;
  min-inline-size: 10px;
}
.awsui_logo_k5dlb_1c946_241.awsui_narrow_k5dlb_1c946_187:not(#\9) {
  max-block-size: var(--space-xl-lmui9r, 24px);
}

.awsui_title_k5dlb_1c946_253:not(#\9) {
  font-size: var(--font-size-heading-m-sjd256, 18px);
  line-height: var(--line-height-heading-m-50evfk, 22px);
  letter-spacing: var(--letter-spacing-heading-m-0w575i, -0.01em);
  font-weight: var(--font-weight-heading-m-m2ekmb, 700);
  -webkit-font-smoothing: var(--font-smoothing-webkit-m8nc84, antialiased);
  -moz-osx-font-smoothing: var(--font-smoothing-moz-osx-foywby, grayscale);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.awsui_no-logo_k5dlb_1c946_237 > .awsui_identity-link_k5dlb_1c946_217 > .awsui_title_k5dlb_1c946_253:not(#\9) {
  font-weight: 700;
}

.awsui_inputs_k5dlb_1c946_268:not(#\9) {
  display: flex;
  flex: 1;
  padding-block: 0;
  padding-inline: var(--space-m-udix3p, 16px);
  justify-content: center;
}

.awsui_search_k5dlb_1c946_276:not(#\9) {
  inline-size: 100%;
  max-inline-size: 340px;
}
.awsui_search-expanded_k5dlb_1c946_280:not(#\9) {
  max-inline-size: none;
}

.awsui_utilities_k5dlb_1c946_284:not(#\9) {
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  align-items: stretch;
  block-size: 100%;
}
.awsui_medium_k5dlb_1c946_187 > .awsui_padding-box_k5dlb_1c946_177 > .awsui_utilities_k5dlb_1c946_284:not(#\9), .awsui_narrow_k5dlb_1c946_187 > .awsui_padding-box_k5dlb_1c946_177 > .awsui_utilities_k5dlb_1c946_284:not(#\9) {
  padding-inline-start: 0;
}

.awsui_utility-wrapper_k5dlb_1c946_295:not(#\9) {
  display: flex;
  position: relative;
  flex-shrink: 0;
  align-items: center;
  padding-block: 0;
  padding-inline: var(--space-m-udix3p, 16px);
}
.awsui_utility-wrapper_k5dlb_1c946_295:not(#\9)::after {
  display: block;
  position: absolute;
  content: "";
  inline-size: 1px;
  inset-inline-end: 0;
  inset-block: var(--space-s-34lx8l, 12px);
  background: var(--color-border-divider-default-cx07f2, #c6c6cd);
}
.awsui_utility-wrapper_k5dlb_1c946_295:not(#\9):last-of-type::after {
  display: none;
}

.awsui_utility-type-button-link_k5dlb_1c946_316:not(#\9) {
  padding-block: 0;
  padding-inline: var(--space-l-t419sm, 20px);
}

.awsui_utility-type-menu-dropdown_k5dlb_1c946_321:not(#\9) {
  padding-block: 0;
  padding-inline: var(--space-s-34lx8l, 12px);
  align-items: stretch;
}
.awsui_utility-type-menu-dropdown_k5dlb_1c946_321:not(#\9):not(.awsui_narrow_k5dlb_1c946_187):last-of-type, .awsui_utility-type-menu-dropdown_k5dlb_1c946_321:not(#\9):not(.awsui_medium_k5dlb_1c946_187):last-of-type {
  padding-inline-end: 0;
}

.awsui_utility-type-button-primary-button_k5dlb_1c946_330:not(#\9) {
  inset-inline-start: -1px;
  border-inline-start: 1px solid var(--color-background-container-content-z79u4u, #ffffff);
}
.awsui_utility-type-button-primary-button_k5dlb_1c946_330:not(#\9)::after {
  display: none;
}

.awsui_utility-link-icon_k5dlb_1c946_338:not(#\9) {
  margin-inline-start: var(--space-xxs-p8yyaw, 4px);
}

.awsui_utility-button-external-icon_k5dlb_1c946_342:not(#\9) {
  display: inline-block;
}

.awsui_offset-right-none_k5dlb_1c946_346:not(#\9) {
  margin-inline-end: 0;
}

.awsui_offset-right-l_k5dlb_1c946_350:not(#\9) {
  margin-inline-end: var(--space-xxs-p8yyaw, 4px);
}

.awsui_offset-right-xxl_k5dlb_1c946_354:not(#\9) {
  margin-inline-end: var(--space-m-udix3p, 16px);
}

/* Overflow Menu */
.awsui_overflow-menu-drawer_k5dlb_1c946_359:not(#\9) {
  position: fixed;
  block-size: 100%;
  inline-size: 100%;
  z-index: 1001;
}

.awsui_overflow-menu_k5dlb_1c946_359:not(#\9) {
  border-collapse: separate;
  border-spacing: 0;
  box-sizing: border-box;
  caption-side: top;
  cursor: auto;
  direction: inherit;
  empty-cells: show;
  font-family: serif;
  font-style: normal;
  font-variant: normal;
  font-stretch: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: start;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  word-spacing: normal;
  font-size: var(--font-size-body-m-x4okxb, 14px);
  line-height: var(--line-height-body-m-30ar75, 20px);
  color: var(--color-text-body-default-7v1jfn, #0f141a);
  font-weight: 400;
  font-family: var(--font-family-base-dnvic8, "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  background: var(--color-background-container-content-z79u4u, #ffffff);
  block-size: 100%;
}

.awsui_overflow-menu-header_k5dlb_1c946_400:not(#\9) {
  display: flex;
  align-items: center;
  min-block-size: var(--font-panel-header-line-height-kxi4u2, 22px);
  padding-block: var(--space-scaled-m-mo5yse, 16px);
  padding-inline: var(--space-scaled-m-mo5yse, 16px);
  border-block-end: var(--border-divider-section-width-1061zr, 1px) solid var(--color-border-divider-default-cx07f2, #c6c6cd);
}
.awsui_overflow-menu-header-text_k5dlb_1c946_408:not(#\9) {
  font-size: var(--font-panel-header-size-edjz3l, 18px);
  line-height: var(--font-panel-header-line-height-kxi4u2, 22px);
  font-weight: var(--font-weight-heading-l-f8711v, 700);
  -webkit-font-smoothing: var(--font-smoothing-webkit-m8nc84, antialiased);
  -moz-osx-font-smoothing: var(--font-smoothing-moz-osx-foywby, grayscale);
  color: var(--color-text-heading-default-jen9ei, #0f141a);
  flex: 1;
  margin-block: 0;
  margin-inline: 0;
  text-align: center;
}
.awsui_overflow-menu-header-text--secondary_k5dlb_1c946_420:not(#\9) {
  font-size: var(--font-header-h2-description-size-2hj97p, 14px);
  line-height: var(--font-header-h2-description-line-height-rtewek, 20px);
  font-weight: 400;
}

.awsui_overflow-menu-header-text--title_k5dlb_1c946_426:not(#\9) {
  /* used in test-utils */
}

.awsui_overflow-menu-back-button_k5dlb_1c946_430:not(#\9) {
  /* used in test-utils */
}

.awsui_overflow-menu-dismiss-button_k5dlb_1c946_434:not(#\9) {
  /* used in test-utils */
}

.awsui_overflow-menu-control_k5dlb_1c946_438:not(#\9) {
  border-collapse: separate;
  border-spacing: 0;
  box-sizing: border-box;
  caption-side: top;
  cursor: auto;
  direction: inherit;
  empty-cells: show;
  font-family: serif;
  font-style: normal;
  font-variant: normal;
  font-stretch: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: start;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  word-spacing: normal;
  font-size: var(--font-size-body-m-x4okxb, 14px);
  line-height: var(--line-height-body-m-30ar75, 20px);
  color: var(--color-text-body-default-7v1jfn, #0f141a);
  font-weight: 400;
  font-family: var(--font-family-base-dnvic8, "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  display: flex;
  inline-size: 100%;
  padding-block: 0;
  padding-inline: 0;
  background: none;
  border-block: none;
  border-inline: none;
}
.awsui_overflow-menu-list-item-utility_k5dlb_1c946_476 > .awsui_overflow-menu-control_k5dlb_1c946_438:not(#\9) {
  padding-block: var(--space-scaled-m-mo5yse, 16px);
  padding-inline: var(--space-scaled-m-mo5yse, 16px);
}
.awsui_overflow-menu-control_k5dlb_1c946_438:not(#\9):hover {
  color: var(--color-text-accent-fptgkc, #006ce0);
  cursor: pointer;
}
.awsui_overflow-menu-control-link_k5dlb_1c946_484:not(#\9) {
  text-decoration: none;
}
.awsui_overflow-menu-control-expandable-menu-trigger_k5dlb_1c946_487:not(#\9) {
  color: var(--color-text-dropdown-group-label-iy2p4t, #424650);
  font-weight: bold;
}
.awsui_overflow-menu-control-expandable-menu-trigger_k5dlb_1c946_487:not(#\9):hover {
  color: var(--color-text-accent-fptgkc, #006ce0);
}
body[data-awsui-focus-visible=true] .awsui_overflow-menu-control_k5dlb_1c946_438:not(#\9):focus {
  position: relative;
}
body[data-awsui-focus-visible=true] .awsui_overflow-menu-control_k5dlb_1c946_438:not(#\9):focus {
  outline: 2px dotted transparent;
  outline-offset: calc(var(--space-button-focus-outline-gutter-cqfd0c, 4px) - 1px);
}
body[data-awsui-focus-visible=true] .awsui_overflow-menu-control_k5dlb_1c946_438:not(#\9):focus::before {
  content: " ";
  display: block;
  position: absolute;
  inset-inline-start: calc(-1 * var(--space-button-focus-outline-gutter-cqfd0c, 4px));
  inset-block-start: calc(-1 * var(--space-button-focus-outline-gutter-cqfd0c, 4px));
  inline-size: calc(100% + var(--space-button-focus-outline-gutter-cqfd0c, 4px) + var(--space-button-focus-outline-gutter-cqfd0c, 4px));
  block-size: calc(100% + var(--space-button-focus-outline-gutter-cqfd0c, 4px) + var(--space-button-focus-outline-gutter-cqfd0c, 4px));
  border-start-start-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  border-start-end-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  border-end-start-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  border-end-end-radius: var(--border-radius-control-default-focus-ring-u8zbsz, 4px);
  box-shadow: 0 0 0 2px var(--color-border-item-focused-nv6mhz, #006ce0);
}

.awsui_overflow-menu-list_k5dlb_1c946_476:not(#\9) {
  list-style: none;
  margin-block: 0;
  margin-inline: 0;
  padding-block: 0;
  padding-inline: 0;
}
.awsui_overflow-menu-list-submenu_k5dlb_1c946_523:not(#\9) {
  margin-block: var(--space-scaled-xxs-7597g1, 4px);
  margin-inline: 0;
}

.awsui_overflow-menu-list-item_k5dlb_1c946_476:not(#\9) {
  box-sizing: border-box;
  letter-spacing: var(--font-button-letter-spacing-65ug0o, 0.005em);
}
.awsui_overflow-menu-list-item-icon_k5dlb_1c946_532:not(#\9) {
  margin-inline-end: var(--space-xxs-p8yyaw, 4px);
}
.awsui_overflow-menu-list-item-text_k5dlb_1c946_535:not(#\9) {
  flex: 1;
}
.awsui_overflow-menu-list-item-utility_k5dlb_1c946_476:not(#\9) {
  font-weight: var(--font-weight-button-c91p4t, 700);
  -webkit-font-smoothing: var(--font-smoothing-webkit-m8nc84, antialiased);
  -moz-osx-font-smoothing: var(--font-smoothing-moz-osx-foywby, grayscale);
  border-block-end: var(--border-divider-section-width-1061zr, 1px) solid var(--color-border-divider-default-cx07f2, #c6c6cd);
}
.awsui_overflow-menu-list-item-submenu_k5dlb_1c946_544:not(#\9) {
  border-block-start: var(--border-divider-section-width-1061zr, 1px) solid transparent;
  border-block-end: var(--border-divider-section-width-1061zr, 1px) solid transparent;
  padding-block: var(--space-scaled-xxs-7597g1, 4px);
  padding-inline: var(--space-scaled-l-0hpmd7, 20px);
}
.awsui_overflow-menu-list-item-dropdown-menu_k5dlb_1c946_550:not(#\9) {
  padding-block: var(--space-scaled-xxs-7597g1, 4px);
  padding-inline: var(--space-scaled-s-aqzyko, 12px);
}
.awsui_overflow-menu-list-item-expandable_k5dlb_1c946_554:not(#\9) {
  border-block-start-color: var(--color-border-divider-default-cx07f2, #c6c6cd);
  border-block-end-color: var(--color-border-divider-default-cx07f2, #c6c6cd);
}

.awsui_icon_k5dlb_1c946_559:not(#\9) {
  transform: rotate(0deg);
  transition: transform var(--motion-duration-rotate-180-dpvl4m, 135ms) var(--motion-easing-rotate-180-e270ko, cubic-bezier(0.165, 0.84, 0.44, 1));
}
@media (prefers-reduced-motion: reduce) {
  .awsui_icon_k5dlb_1c946_559:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_icon_k5dlb_1c946_559:not(#\9), .awsui-mode-entering .awsui_icon_k5dlb_1c946_559:not(#\9) {
  animation: none;
  transition: none;
}
.awsui_icon-open_k5dlb_1c946_573:not(#\9) {
  transform: rotate(-180deg);
}