/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @cloudscape-design/no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_error-icon-shake-wrapper_14mhv_tih1t_153:not(#\9),
.awsui_warning-icon-shake-wrapper_14mhv_tih1t_154:not(#\9) {
  animation: awsui_awsui-motion-shake-horizontally_14mhv_tih1t_1 var(--motion-duration-refresh-only-medium-nf6485, 165ms);
}
@keyframes awsui_awsui-motion-shake-horizontally_14mhv_tih1t_1 {
  0% {
    transform: translateX(-5px);
    animation-timing-function: linear;
  }
  50% {
    transform: translateX(5px);
    animation-timing-function: var(--motion-easing-refresh-only-a-8flf9q, cubic-bezier(0, 0, 0, 1));
  }
  100% {
    transform: translateX(0px);
  }
}
@media (prefers-reduced-motion: reduce) {
  .awsui_error-icon-shake-wrapper_14mhv_tih1t_153:not(#\9),
  .awsui_warning-icon-shake-wrapper_14mhv_tih1t_154:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_error-icon-shake-wrapper_14mhv_tih1t_153:not(#\9), .awsui-mode-entering .awsui_error-icon-shake-wrapper_14mhv_tih1t_153:not(#\9), .awsui-motion-disabled .awsui_warning-icon-shake-wrapper_14mhv_tih1t_154:not(#\9), .awsui-mode-entering .awsui_warning-icon-shake-wrapper_14mhv_tih1t_154:not(#\9) {
  animation: none;
  transition: none;
}

.awsui_error-icon-scale-wrapper_14mhv_tih1t_184:not(#\9),
.awsui_warning-icon-scale-wrapper_14mhv_tih1t_185:not(#\9) {
  animation: awsui_awsui-motion-scale-popup_14mhv_tih1t_1 var(--motion-duration-refresh-only-medium-nf6485, 165ms) var(--motion-easing-refresh-only-a-8flf9q, cubic-bezier(0, 0, 0, 1));
}
@keyframes awsui_awsui-motion-scale-popup_14mhv_tih1t_1 {
  0% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
}
@media (prefers-reduced-motion: reduce) {
  .awsui_error-icon-scale-wrapper_14mhv_tih1t_184:not(#\9),
  .awsui_warning-icon-scale-wrapper_14mhv_tih1t_185:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_error-icon-scale-wrapper_14mhv_tih1t_184:not(#\9), .awsui-mode-entering .awsui_error-icon-scale-wrapper_14mhv_tih1t_184:not(#\9), .awsui-motion-disabled .awsui_warning-icon-scale-wrapper_14mhv_tih1t_185:not(#\9), .awsui-mode-entering .awsui_warning-icon-scale-wrapper_14mhv_tih1t_185:not(#\9) {
  animation: none;
  transition: none;
}

.awsui_warning_14mhv_tih1t_154:not(#\9),
.awsui_error_14mhv_tih1t_153:not(#\9) {
  animation: awsui_awsui-motion-fade-in-0_14mhv_tih1t_1 var(--motion-duration-refresh-only-medium-nf6485, 165ms) var(--motion-easing-refresh-only-a-8flf9q, cubic-bezier(0, 0, 0, 1));
}
@keyframes awsui_awsui-motion-fade-in-0_14mhv_tih1t_1 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@media (prefers-reduced-motion: reduce) {
  .awsui_warning_14mhv_tih1t_154:not(#\9),
  .awsui_error_14mhv_tih1t_153:not(#\9) {
    animation: none;
    transition: none;
  }
}
.awsui-motion-disabled .awsui_warning_14mhv_tih1t_154:not(#\9), .awsui-mode-entering .awsui_warning_14mhv_tih1t_154:not(#\9), .awsui-motion-disabled .awsui_error_14mhv_tih1t_153:not(#\9), .awsui-mode-entering .awsui_error_14mhv_tih1t_153:not(#\9) {
  animation: none;
  transition: none;
}

.awsui_root_14mhv_tih1t_236:not(#\9) {
  border-collapse: separate;
  border-spacing: 0;
  box-sizing: border-box;
  caption-side: top;
  cursor: auto;
  direction: inherit;
  empty-cells: show;
  font-family: serif;
  font-style: normal;
  font-variant: normal;
  font-stretch: normal;
  -webkit-hyphens: none;
          hyphens: none;
  letter-spacing: normal;
  list-style: disc outside none;
  tab-size: 8;
  text-align: start;
  text-indent: 0;
  text-shadow: none;
  text-transform: none;
  visibility: visible;
  white-space: normal;
  word-spacing: normal;
  font-size: var(--font-size-body-m-x4okxb, 14px);
  line-height: var(--line-height-body-m-30ar75, 20px);
  color: var(--color-text-body-default-7v1jfn, #0f141a);
  font-weight: 400;
  font-family: var(--font-family-base-dnvic8, "Open Sans", "Helvetica Neue", Roboto, Arial, sans-serif);
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  box-sizing: border-box;
}

.awsui_label_14mhv_tih1t_269:not(#\9) {
  box-sizing: border-box;
  color: var(--color-text-form-label-5nbxa6, #0f141a);
  display: inline;
  font-size: var(--font-size-body-m-x4okxb, 14px);
  line-height: var(--line-height-body-m-30ar75, 20px);
  font-weight: var(--font-display-label-weight-815ja9, 700);
  margin-inline-end: var(--space-xs-zb16t3, 8px);
}
.awsui_label_14mhv_tih1t_269:not(#\9):only-child {
  margin-block: 0;
  margin-inline: 0;
}

.awsui_info_14mhv_tih1t_283:not(#\9) {
  display: inline-flex;
  padding-inline-start: var(--space-xs-zb16t3, 8px);
  border-inline-start: var(--border-divider-section-width-1061zr, 1px) solid var(--color-border-divider-default-cx07f2, #c6c6cd);
}

.awsui_description_14mhv_tih1t_289:not(#\9),
.awsui_constraint_14mhv_tih1t_290:not(#\9) {
  color: var(--color-text-form-secondary-54emib, #656871);
  font-size: var(--font-size-body-s-asqx2i, 12px);
  line-height: var(--line-height-body-s-7zv1j5, 16px);
  letter-spacing: var(--letter-spacing-body-s-z9jkwp, 0.005em);
}

.awsui_hints_14mhv_tih1t_297:not(#\9),
.awsui_constraint-has-validation-text_14mhv_tih1t_298:not(#\9) {
  padding-block-start: var(--space-xxs-p8yyaw, 4px);
}

.awsui_secondary-control_14mhv_tih1t_302:not(#\9) {
  /* used in test-utils */
}

.awsui_controls_14mhv_tih1t_306:not(#\9):not(.awsui_label-hidden_14mhv_tih1t_306) {
  padding-block-start: var(--space-xxs-p8yyaw, 4px);
}
.awsui_label-wrapper_14mhv_tih1t_309:not(#\9):empty + .awsui_controls_14mhv_tih1t_306 {
  padding-block-start: 0;
}

.awsui_control_14mhv_tih1t_306:not(#\9) {
  min-inline-size: 0;
  word-break: break-word;
}

.awsui_error_14mhv_tih1t_153:not(#\9) {
  color: var(--color-text-status-error-5676bj, #db0000);
  font-size: var(--font-size-body-s-asqx2i, 12px);
  line-height: var(--line-height-body-s-7zv1j5, 16px);
  letter-spacing: var(--letter-spacing-body-s-z9jkwp, 0.005em);
  display: flex;
  align-items: flex-start;
}

.awsui_warning_14mhv_tih1t_154:not(#\9) {
  color: var(--color-text-status-warning-csaw41, #855900);
  font-size: var(--font-size-body-s-asqx2i, 12px);
  line-height: var(--line-height-body-s-7zv1j5, 16px);
  letter-spacing: var(--letter-spacing-body-s-z9jkwp, 0.005em);
  display: flex;
  align-items: flex-start;
}

.awsui_error__message_14mhv_tih1t_336:not(#\9),
.awsui_warning__message_14mhv_tih1t_337:not(#\9) {
  margin-inline-start: var(--space-xxs-p8yyaw, 4px);
}

.awsui_visually-hidden_14mhv_tih1t_341:not(#\9) {
  position: absolute !important;
  inset-block-start: -9999px !important;
  inset-inline-start: -9999px !important;
}